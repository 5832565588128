import React, {useEffect, useState} from 'react'
import {Container, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import {Flexed, Spacer, Text} from '../styles/shared'
// import DropDown from "../components/common/DropDown";
import OrdersTable from '../components/tables/OrdersTable'
import CustomDropDown from '../components/common/CustomDropDown'
// import ProfileViewModal from "../components/modals/ProfileViewModal";
import OrdersDetailsModal from '../components/modals/OrdersDetailsModal'
import DateSelector from '../components/common/DateSelector'
import {getAllOrders} from '../api/apis'
import {setIsLoading} from '../actions/authActions'
import {useDispatch} from 'react-redux'
import {toastError, toastSuccess} from '../styles/toasterStyle'
const Orders = () => {
	const [openOrdersDetail, setOpenOrdersDetails] = useState(false)
	const [pageNumber, setPageNumber] = useState(1)
	const [pagesAmount, setPagesAmount] = useState(1)
	const [totalNumberOfOrders, setTotalNumberOfOrders] = useState(1)
	const [filterOptions, setFilterOptions] = useState([
		{label: '#8786436', value: 1},
		{label: '#12386436', value: 2},
		{label: '#34566436', value: 3}
	])
	const _dispatch = useDispatch()
	const [tableTest, setTableTest] = useState([])

	const doGetOrders = async () => {
		_dispatch(setIsLoading(true))

		const response = await getAllOrders(pageNumber, 6)
		// console.log("getorder/////respone",response)
		if (response?.success) {
			setPagesAmount(Math.ceil(response?.count / 6))
			setTotalNumberOfOrders(response?.count)
			const apiRows = response?.data?.map((order,index) => {
				return {
					id: (
						<Text type="medium" color="black" fontWeight={300}>
							{index+1}
						</Text>
					),
					customer: (
						<div
							onClick={() => {
								setOpenOrdersDetails(true)
							}}>
							<Text type="medium" color="black" fontWeight={300}>
								{order?.customer?.company_name}
							</Text>
						</div>
					),
					email: (
						<Text type="medium" color="black" fontWeight={300}>
							{order?.customer?.customer_email}
						</Text>
					),
					items: (
						<Text type="medium" color="black" fontWeight={300}>
							{order?.items?.map((item) => item.productName).join(', ')}
						</Text>
					),
					orderValue: (
						<Text type="medium" color="black" fontWeight={300}>
							{order?.totalPrice}
						</Text>
					),
					status: <Status orderStatus={order?.orderStatus}>{order?.orderStatus}</Status>,
					icon: (
						<Flexed direction="row" align="center" gap={0.75} justify="flex-start">
							<IconWrapper>
								<Icon src="icons/pencil.svg" />
							</IconWrapper>
						</Flexed>
					)
				}
			})

			setTableTest(apiRows)
			// toastSuccess(response?.message);
		} else {
			toastError(response?.message)
		}

		_dispatch(setIsLoading(false))
	}
	useEffect(() => {
		doGetOrders()
	}, [pageNumber])

	return (
		<Wrapper>
			<Container fluid>
				<SearchSection title="Orders" />
				<Spacer height={0.625} />
				<Flexed direction="row" align="center" justify="flex-end">
					<Flexed direction="row" align="center" flexWrap="wrap" gap={1}>
						<Text type="xlarge" color="black" fontWeight={700}>
							Filter By:
						</Text>
						<Flexed direction="row" align="center" flexWrap="wrap" gap={0.5}>
							<CustomDropDown
								label="SP Code"
								styledHeight={6}
								// firstSelected={'Review'}
								hasChanged={(value) => {
									// setSelectedDueDateError('')
									// if (value == '') {
									// 	setSelectedDueDateError('due is Required')
									// }
									// setSelectedDueDate(value)
								}}
								width="8"
								// required
								// error={selectedDueDateError}
								// errorMsg={selectedDueDateError}
								options={filterOptions}
							/>
							<DateSelector />
							{/* <CustomDropDown
                label="Date"
                styledHeight={6}
                // firstSelected={selectedEditProduct?.company?.company_name}
                hasChanged={(value) => {
                  // setSelectedDueDateError('')
                  // if (value == '') {
                  // 	setSelectedDueDateError('due is Required')
                  // }
                  // setSelectedDueDate(value)
                }}
                width="8"
                // required
                // error={selectedDueDateError}
                // errorMsg={selectedDueDateError}
                options={filterOptions}
              /> */}
						</Flexed>
					</Flexed>
				</Flexed>
				<Spacer height={0.625} />
				<TableWrapper>
					<OrdersTable tableTest={tableTest} pageNumber={pageNumber} setPageNumber={setPageNumber} pagesAmount={pagesAmount} totalNumberOfOrders={totalNumberOfOrders} />
				</TableWrapper>
			</Container>
			{openOrdersDetail && (
				<OrdersDetailsModal
					onClose={() => {
						setOpenOrdersDetails(false)
					}}
				/>
			)}
		</Wrapper>
	)
}

const Status = styled.div`
	width: 86px;
	border-radius: 20px;
	font-weight: 500;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({orderStatus}) => (orderStatus === 'Pending' ? palette.yellow : orderStatus === 'accepted' ? palette.white : orderStatus === 'Refund' ? palette.black : palette.black)};
	background-color: ${({orderStatus}) => (orderStatus === 'Pending' ? palette.black : orderStatus === 'accepted' ? palette.yellow : orderStatus === 'Refund' ? palette.customGrey : palette.customGrey)};
`

const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	height: 100vh;
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const TableWrapper = styled.div`
	border-radius: 0.625rem;
	background-color: ${palette.white};
	padding: 0.75rem;
`

const IconWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Icon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
`

export default Orders
