import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {palette} from '../../styles/colors'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import {Flexed, Heading, Spacer, Text, VerticalSpacer} from '../../styles/shared'
import {updateProductData , generateImageLink , deleteImageLink} from '../../api/apis'
import {IoMdClose} from 'react-icons/io'
import CustomDropDown from '../common/CustomDropDown'
import Button from '../common/Button'
import CheckBox from '../common/CheckBox'
import InputField from '../common/InputField'
import EditProductForm from '../EditProductForm'
import SuccessModal from './SuccessModal'
import CustomCheckBox from '../common/CustomCheckBox'
import {useDispatch} from 'react-redux'
import {setIsLoading} from '../../actions/authActions'
import {toastError, toastSuccess} from '../../styles/toasterStyle'
const EditProductsModal = ({onClose, updateProduct, doGetProducts}) => {
	const _dispatch = useDispatch()
	const [openSuccessModal, setOpenSuccessModal] = useState(false)
	const [Media, setMedia] = useState([])
	const [profilImage, setProfileImage] = useState('')
	const [showImage, setShowImage] = useState(null)
	const [filterOptions, setFilterOptions] = useState([
		{label: 'Distributer', value: 1},
		{label: 'Shopkeeper', value: 2}
	])
	const [productName, setProductName] = useState("");
	const [productCategory, setProductCategory] = useState("");
	const [productDesc, setProductDesc] = useState("");
	const [productQuantity, setProductQuantity] = useState("");
	const [distributerPrice, setDistributerPrice] = useState("");
	const [onlinePowerHousePrice, setOnlinePowerHousePrice] = useState("");
	const [retailorPrice, setRetailorPrice] = useState("");
	const [newDeals, setNewDeals] = useState(false);
	const [comingSoon, setComingSoon] = useState(false);
	const [productFullfilment, setProductFullfilment] = useState("");
	const [productAvailable, setProductAvailable] = useState(false);
	const [imgData, setImgData] = useState([]);

	 // Error states
	 const [productNameError, setProductNameError] = useState("");
	 const [productCategoryError, setProductCategoryError] = useState("");
	 const [productDescError, setProductDescError] = useState("");
	 const [productQuantityError, setProductQuantityError] = useState("");
	 const [distributerPriceError, setDistributerPriceError] = useState("");
	 const [onlinePowerHousePriceError, setOnlinePowerHousePriceError] = useState("");
	 const [retailorPriceError, setRetailorPriceError] = useState("");


	 useEffect(()=>{
		setProductName(updateProduct?.productName);
		setProductCategory(updateProduct?.productGroup);
		setProductDesc(updateProduct?.productDesc);
		setProductQuantity(updateProduct?.productQuantity);
		setDistributerPrice(updateProduct?.distributerPrice);
		setOnlinePowerHousePrice(updateProduct?.onlinePowerHousePrice);
		setRetailorPrice(updateProduct?.retailorPrice);
		setNewDeals(updateProduct?.newDeals || false);
		setComingSoon(updateProduct?.comingSoon || false);
		setProductFullfilment(updateProduct?.productFullfilment);
		setProductAvailable(updateProduct?.productAvailable || false);
		setMedia(updateProduct?.imgData || []);
		setImgData(updateProduct?.imgData || []);
	 },[])


	 const doUpdateProduct = async () => {

		console.log('object :>> ', Media);

		const regularUrls = Media.filter(url => !url.startsWith("data:"));
         const base64Urls = Media.filter(url => url.startsWith("data:"));
        const UrlstoDelete = imgData.filter(item => !regularUrls.includes(item));

		_dispatch(setIsLoading(true))
		const updatedImageData = [...regularUrls];
        
		const imageDelete = await Promise.all(UrlstoDelete?.map(async (item) => {
			const uploadImg = await deleteImageLink(item);
			if (uploadImg.success === true) {
				return { success: true };
			} else {
				return { success: false };
			}
		}));

		const imageHit = await Promise.all(base64Urls?.map(async (item) => {
			const uploadImg = await generateImageLink(item);
			if (uploadImg.success === true) {
				updatedImageData.push(uploadImg.data);
				console.log('uploadImg.data :>> ', uploadImg.data);
				return { success: true };
			} else {
				return { success: false };
			}
		}));
	

		const updateData={
			productName:productName,
			productDesc:productDesc,
			productQuantity:productQuantity,
			distributerPrice:distributerPrice,
			onlinePowerHousePrice:onlinePowerHousePrice,
			retailorPrice:retailorPrice,
			newDeals:newDeals,
			comingSoon:comingSoon,
			productFullfilment:productFullfilment,
			productAvailable:productAvailable,
			imgData:updatedImageData,
		}
		console.log('imageHit >> :>> ', imageHit);
		if ((imageHit.every(img => img.success === true)) && (imageDelete.every(img => img.success === true))) {
		const response = await updateProductData(updateProduct?.id,updateData)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			// toastSuccess(response?.message)
			setOpenSuccessModal(true)
			// onClose()
		} else {
			toastError(response?.message)
		}
	}
		_dispatch(setIsLoading(false))
	}


	const CustomhandleCapture = ({target}) => {
		// debugger
		if (target.files[0]) {
			const reader = new FileReader()
			reader.readAsDataURL(target.files[0])
			reader.onload = () => {
				if (reader.readyState === 2) {
					setMedia((prev) => [...prev, reader.result])
				}
			}
		}
		if (target.files[1]) {
			const reader = new FileReader()
			reader.readAsDataURL(target.files[1])
			reader.onload = () => {
				if (reader.readyState === 2) {
					// setShowArrayImg(reader.result)
					setMedia((prev) => [reader.result, ...prev])
				}
			}
		}
	}

	const deleteSelectImage = (index) => {
		const deleteImage = Media.filter((value, ind) => {
			return ind !== index
		})
		setMedia(deleteImage)
	}

	const isValidURL = (string) => {
		try {
			new URL(string);
			return true;
		} catch (error) {
			return false;
		}
	}

	const handleCapture = ({target}) => {
		const reader = new FileReader()
		reader.readAsDataURL(target.files[0])
		reader.onload = () => {
			if (reader.readyState === 2) {
				setShowImage(reader.result)
				setProfileImage(reader.result)
			}
		}
	}
	useEffect(()=>{
      console.log('media :>> ', Media);
	},[Media])
	
	return (
		<>
			<Modal
				open={true}
				onClose={onClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Container>
					
						<Row>
							<Col lg={8}>
								{/* <EditProductForm /> */}

								<Form>
				<Row>
					<Col lg={6}>
						<InputField
						
						value={productName}
							labelType="xlarge"
							label="Name"
							placeholder="Zero nicotine"
							handleChange={(e) => {
								setProductNameError('')
								if (e === '') {
									setProductNameError('name is required')
								}
							setProductName(e)
							}}
							error={productNameError}
							errorMsg={productNameError}
							required
						/>
						<Spacer height={1} />
					</Col>
					<Col lg={6}>
						<InputField
							labelType="xlarge"
							label="Category"
							value={productCategory}
							placeholder="Bar Series"
							// handleChange={(e) => {
							// 	setCustomerEmailError('')
							// 	if (e === '') {
							// 		setCustomerEmailError('Email is required')
							// 	}
							// 	setCustomerEmail(e)
							// }}
							// error={customerEmailError}
							// errorMsg={customerEmailError}
							disabled={true}
						/>
					</Col>
					<Col>
						<InputField
							labelType="xlarge"
							type="textarea"
							value={productDesc}
							label="Description"
							placeholder="Name"
							handleChange={(e) => {
								setProductDescError('')
								if (e === '') {
									setProductDescError('Description is required')
								}
								setProductDesc(e)
							}}
							error={productDescError}
							errorMsg={productDescError}
							required
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Remaining qty"
							value={productQuantity}
							placeholder="2500"
							allowPhoneNumberOnly
							handleChange={(e) => {
								setProductQuantityError('')
								if (e === '') {
									setProductQuantityError('Quantity is required')
								}
								setProductQuantity(e)
							}}
							error={productQuantityError}
							errorMsg={productQuantityError}
							required
						/>

						<Spacer height={1} />
					</Col>
				</Row>
				<Row>
					<Col>
						<Spacer height={1.625} />
						<Heading fontSize={1.5} fontWeight={700}>
							Prices
						</Heading>
						<Spacer height={1} />
					</Col>
					<Col lg={6}>
						<InputField
							labelType="xlarge"
							label="Distributors"
							value={distributerPrice}
							allowPhoneNumberOnly
							placeholder="435"
							handleChange={(e) => {
								setDistributerPriceError('')
								if (e === '') {
									setDistributerPriceError('Distributor Price is required')
								}
								setDistributerPrice(e)
							}}
							error={distributerPriceError}
							errorMsg={distributerPriceError}
							required
						/>

						<Spacer height={1} />
					</Col>
					<Col lg={6}>
						<InputField
							labelType="xlarge"
							value={onlinePowerHousePrice}
							label="Online power house"
							placeholder="456"
							handleChange={(e) => {
								setOnlinePowerHousePriceError('')
								if (e === '') {
									setOnlinePowerHousePriceError('Online Power House Price is required')
								}
								setOnlinePowerHousePrice(e)
							}}
							error={onlinePowerHousePriceError}
							errorMsg={onlinePowerHousePriceError}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<InputField
							labelType="xlarge"
							label="Retailor"
							placeholder="635"
							value={retailorPrice}
							handleChange={(e) => {
								setRetailorPriceError('')
								if (e === '') {
									setRetailorPriceError('Retailor Price is required')
								}
								setRetailorPrice(e)
							}}
							error={retailorPriceError}
							errorMsg={retailorPriceError}
							required
						/>

						<Spacer height={1} />
					</Col>
					<Col lg={6}>
						<CustomFlexed justify="space-between" align="center">
							<CustomCheckBox isChecked={newDeals} setIsChecked={()=>{setNewDeals(!newDeals)}} label="New deals" right="0.5" />
							<CustomCheckBox isChecked={comingSoon} setIsChecked={()=>{setComingSoon(!comingSoon)}} label="Coming soon" right="0.5" />
						</CustomFlexed>
					</Col>
				</Row>
				<Spacer height="1" />
				<Row>
					<CustomCol lg={6}>
						<Col lg={9}>
							<Text fontSize="1.25" lineHeight="1" color="black">
								Product fullfillment &nbsp;<InnetText>(in days)</InnetText>
							</Text>
						</Col>
						<Col lg={3}>
							<InputField
								placeholder="635"
								handleChange={(e) => {
									// setCustomerNameError('')
									// if (e === '') {
									// 	setCustomerNameError('name is required')
									// }
									setProductFullfilment(e)
								}}
								// error={customerNameError}
								// errorMsg={customerNameError}
								required
							/>
						</Col>
					</CustomCol>
					<Col lg={6}>
						<CustomFlexed justify="space-between" align="center">
							<CustomCheckBox label="Product Available" isChecked={productAvailable} setIsChecked={()=>{setProductAvailable(!productAvailable)}} />
						</CustomFlexed>
					</Col>
				</Row>
			</Form>
							</Col>
							<CustomCol lg={4}>
								<Spacer height={2} />
								<CustomFlex justify="center" align="center">
									<Image src="/icons/logo.png" />
								</CustomFlex>

								<Row>
									<Col>
										<Spacer height={3} />
										<Text color="black" fontSize="1.5" fontWeight="700">
											Product Gallery
										</Text>
										<Spacer height={1} />
										<InputWrapper>
											<CustomUpload dashed>
												<UploadIcon showImage={showImage || profilImage} src={showImage ? showImage : profilImage ? process.env.REACT_APP_PUBLIC_IMAGE_URL + profilImage : '/icons/mountains.png'} />
												<Text isCentered lineHeight={1} type="small" color="black">
													Drop your imager here, or browse Jpeg, png are allowed
												</Text>
												{/* <Icon src="icons/add.svg" /> */}
												<FileInput
													id="faceImage"
													accept="image/jpeg/png"
													type="file"
													onChange={(e) => {
														// if (Media.length < 3)
														 CustomhandleCapture(e)
														// setShowImage('')
													}}
												/>
											</CustomUpload>
										</InputWrapper>
									</Col>
								</Row>
								<CustomRowImages height={12.5}>
									<Spacer height={2} />
									{Media.map((value, index) => {
										return (
											<Col xs={6} sm={6} md={4} lg={4} key={index}>
												<Spacer height={2} />

												<ImgWrapper>
													<ShowImage src={value ? value : `${Media}`} />
													<IconWrapper
														onClick={() => {
															deleteSelectImage(index)
														}}>
														<CrossIcon />
													</IconWrapper>
												</ImgWrapper>
												<Spacer height={1.5} />
											</Col>
										)
									})}
									{Media.length > 3 ? (
										<Col>
											{/* <Spacer height={0.5} />
												<Text fontSize={0.625} type="small" color="danger">
													You can Add Maximum 2 images
												</Text>
												<Spacer height={1.5} /> */}
										</Col>
									) : (
										Media.length < 3 && (
											<Col xs={6} sm={6} md={4} lg={4}>
												<Spacer height={2} />

												{/* <CustomInputWrapper>
													<CustomUploadMedia className="upload">
														<UploadIcon src="icons/inputfile.png" />
														<FileInput
															multiple
															id="faceImage"
															accept="image/jpeg/png"
															type="file"
															// onChange={(e) => {

															// }}
														/>
													</CustomUploadMedia>
												</CustomInputWrapper> */}

												{Media.length ? (
													''
												) : (
													<Text fontSize={0.625} type="small" color="danger" textTransform="lowercase">
														{/* {imageError} */}
													</Text>
												)}
												<Spacer height={1.5} />
											</Col>
										)
									)}

									{/* ############################################################# */}
								</CustomRowImages>
								<CustomRow>
									<CustomColV lg={6}>
										<Button
											width="100%"
											hasBorder
											ifClicked={() => {
												onClose()
											}}
											label="Cancel"
										/>
									</CustomColV>
									<CustomColV lg={6}>
										<Button
											width="100%"
											ifClicked={() => {
												doUpdateProduct()
												// setOpenSuccessModal(true)
											}}
											label="Update"
										/>
									</CustomColV>
								</CustomRow>
							</CustomCol>
						</Row>
					</Container>
				</ModalWrapper>
			</Modal>
			{openSuccessModal && (
				<SuccessModal
					label="Product edit successfully"
					onClose={async () => {
						await doGetProducts();
						setOpenSuccessModal(false)
						onClose()
					}}
				/>
			)}
		</>
	)
}

const CustomRowImages = styled(Row)`

overflow-y: auto;

  height: ${({ height }) => `${height}rem`};

  &::-webkit-scrollbar {
    width: 0.5rem !important;
    height: 0.1rem !important;
  }

  &::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 1rem;
    border: 0.01rem solid ${palette.silver};
    border-width: 0rem 0.1rem 0rem 0rem;

`
const CustomRow = styled(Row)``
const CustomColV = styled(Col)`
	display: flex;
	justify-content: flex-end;
`

const LabelWrapper = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	top: 5.8rem;
	color: white;
	// left: 4.5rem;
	//   background-color: ${palette.white};
	padding: 0 0.5rem;
`

const CustomInputWrapper = styled.div`
	position: relative;
`
const InputWrapper = styled.div`
	position: relative;

	// height: 8.5rem;
`

const UploadIcon = styled.img`
	width: ${({showImage}) => (showImage ? '100%' : '3rem')};
	height: ${({showImage}) => (showImage ? '100%' : '3rem')};
	//   border-radius: 100%;
	/* font-size: 2.5rem; */
	/* color: ${palette.black}; */
`
const FileInput = styled.input`
	display: none !important;
`
const CustomUploadMedia = styled.label`
	padding: 1rem;
	height: 8.5rem;
	// border: 0.063rem solid ${palette.input_border};
	border-radius: 0.375rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.7rem;
	flex-direction: column;
	cursor: pointer;
`
const CustomUpload = styled.label`
	padding: 1rem;
	height: 8.5rem;
	border: 0.063rem dashed ${palette.input_border};
	border-radius: 0.375rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.7rem;
	flex-direction: column;
	cursor: pointer;
`

const ShowImage = styled.img`
	width: 100%;
	height: 8.5rem;
	object-fit: cover;
	border-radius: 0.375rem;
`

const CrossIcon = styled(IoMdClose)`
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
`
const IconWrapper = styled.div``
const CustomCol = styled(Col)``

const ImgWrapper = styled.div`
	position: relative;
`

const CustomFlex = styled(Flexed)`
	width: 100% !important;
`

const ModalWrapper = styled.div`
	padding: 1.125rem 0rem;
	height: 100%;
	background: ${palette.white};
`

const Image = styled.img`
	width: 60%;
	// height:15.5rem;
`
const Form = styled.div``

const InnetText = styled.span`
	font-size: 0.85rem;
	color: black;
`


const CustomFlexed = styled(Flexed)`
	width: 100% !important;
	height: 100% !important;
`

export default EditProductsModal
