import React, {useEffect, useState} from 'react'
import {styled} from 'styled-components'
import {Text} from '../../styles/shared'
import {palette} from '../../styles/colors'

const SideButton = ({icon, name, padding, active}) => {
	return (
		<Wrapper padding={padding} active={active}>
			<Icon>
				<CustomIcon src={icon} />
			</Icon>
			<StyledText active={active} fontSize={1.5} lineHeight={2.5} fontWeight={600}>
				{name}
			</StyledText>
		</Wrapper>
	)
}

const CustomIcon = styled.img`
	width: 100%;
	height: 100%;
`
const Icon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.5rem;
	height: 1.5rem;
	color: ${palette.white};
`

const StyledText = styled(Text)`
	color: ${({active}) => (active ? palette.white : palette.white)};
	/* &:hover {
		color: ${palette.yellow};
	} */
`

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.75rem;
	padding: ${({padding}) => (padding ? `${padding}` : '0.625rem 2rem')};
	/* border-right: ${({active}) => (active ? `2px solid ${palette.yellow}` : '')}; */
	color: ${({active}) => (active ? palette.yellow : palette.text)};
	background-color: ${({active}) => (active ? palette.yellow : palette.text)};
	cursor: pointer;
	border-radius: 0.625rem;
	&:hover {
		/* color: ${palette.yellow}; */
	}
	&:hover ${StyledText} {
		/* color: ${palette.yellow}; */
	}
`

export default SideButton
