import React, {useState} from 'react'
import styled from 'styled-components'
import {palette} from '../../styles/colors'
import {Flexed, Text} from '../../styles/shared'
import {BsFillEyeFill, BsFillEyeSlashFill} from 'react-icons/bs'
import {IoSend} from 'react-icons/io5'
import {IoAttach} from 'react-icons/io5'

const InputField = ({
	allowNumber,
	allowPhoneNumberOnly,
	allowText,
	maxLength,
	allowOnlyNumbers,
	keyValue,
	allowOnlyNumbersAndDecimal,
	labelType,
	width,
	customIcon,
	icon,
	label,
	value,
	required,
	type,
	placeholder,
	handleChange,
	disabled,
	error,
	errorMsg,
	bgTransparent
}) => {
	const [visible, setVisible] = useState(false)

	const onChange = (event) => {
		if (allowNumber) {
			const re = /^[0-9 ]+/
			let val = event.target.value.replace(' ', '')
			if (re.test(val) || val?.length == 0) {
				if (handleChange) handleChange(event.target.value.replace(/[\s]/g, ''))
			}
		} else if (allowPhoneNumberOnly) {
			const cleanedPhoneNumber = event?.target?.value.replace(/\D/g, '')
			console.log('cleanedPhoneNumber', cleanedPhoneNumber)
			handleChange(cleanedPhoneNumber)
		} else if (allowText) {
			const re = /^[a-z]+$/
			let val = event.target.value
			if (re.test(val) || val?.length == 0) {
				if (handleChange) handleChange(event.target.value)
			}
		} else if (allowOnlyNumbers) {
			const re = /^\d+$/
			let val = event.target.value.replace(' ', '')

			if (re.test(val) || val?.length == 0) {
				if (handleChange) handleChange(event.target.value.replace(/[\s]/g, ''))
			}
		} else if (allowOnlyNumbersAndDecimal) {
			const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/
			let val = event.target.value.replace(' ', '')
			if (keyValue == 'Backspace' || keyValue == 'Delete') {
				handleChange(event.target.value)
			} else {
				if (re.test(val) || val?.length == 0) {
					if (handleChange) handleChange(event.target.value.replace(/[\s]/g, ''))
				}
			}
		} else {
			if (handleChange) handleChange(event.target.value)
		}
	}
	return (
		<>
			{label && (
				<Label type={labelType} fontWeight={700} margin="0rem 0rem 0.19rem 0rem">
					{label} {required ? <Mandatory>*</Mandatory> : ''}
				</Label>
			)}
			{type !== 'textarea' ? (
				<>
					<InputWrapper>
						<TextInput
							width={width}
							customIcon={customIcon}
							maxLength={maxLength}
							value={value}
							type={`${type && !visible ? (type === 'search' ? 'search' : type) : 'text'}`}
							disabled={disabled}
							placeholder={placeholder}
							error={error}
							onChange={(event) => onChange(event)}
							bgTransparent={bgTransparent}
						/>
						{type === 'password' && (
							<Icon direction="row" align="center" justify="center">
								{visible ? (
									<Eye
										onClick={() => {
											setVisible(false)
										}}
									/>
								) : (
									<CloseEye
										onClick={() => {
											setVisible(true)
										}}
									/>
								)}
							</Icon>
						)}
						{type === 'search' && (
							<StyledIcon direction="row" align="center" justify="center">
								<Search src="/icons/search.svg" />
							</StyledIcon>
						)}
						{icon === 'email' && (
							<StyledIcon direction="row" align="center" justify="center">
								<Search src="/icons/email.svg" />
							</StyledIcon>
						)}
						{icon === 'password' && (
							<StyledIcon direction="row" align="center" justify="center">
								<Search src="/icons/password.svg" />
							</StyledIcon>
						)}
						{icon === 'inputProfile' && (
							<StyledIcon direction="row" align="center" justify="center">
								<Search src="/icons/inputProfile.svg" />
							</StyledIcon>
						)}
					</InputWrapper>
					{required && error && !disabled && (
						<Text fontSize={0.625} type="small" color="danger" textTransform="lowercase">
							{errorMsg}
						</Text>
					)}
				</>
			) : (
				<>
					<TextArea bgTransparent={bgTransparent} row={4} value={value} placeholder={placeholder} onChange={(event) => handleChange(event.target.value)} />
					{required && error && !disabled && (
						<Text fontSize={0.625} type="small" color="danger" textTransform="lowercase">
							{errorMsg}
						</Text>
					)}
				</>
			)}
		</>
	)
}

const Label = styled(Text)`
	/* font-weight: 500; */
	text-transform: capitalize;
	color: ${palette.black};
`

const Mandatory = styled.span`
	color: ${palette.danger};
`

const InputWrapper = styled.div`
	position: relative;
`

const TextInput = styled.input`
	font-family: 'Public Sans';
	width: ${({width}) => (width ? `${width}rem` : '100%')};
	line-height: 2rem;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	border-radius: 9px;
	padding: ${({type, customIcon}) => (type === 'search' || customIcon === true ? ' 0.5rem 0.8rem 0.5rem 2.8rem' : ' 0.5rem 0.8rem')};
	border: 1px solid ${({error, disabled}) => (disabled ? `${palette.lightGrey}` : error ? `${palette.danger}` : `${palette.customGrey}`)};
	color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.black}`)};
	background: ${({disabled, bgTransparent}) => (bgTransparent ? 'transparent' : disabled ? `${palette.white}` : `${palette.white}`)};
	cursor: ${({disabled}) => (disabled ? `no-drop` : `pointer`)};

	&:focus {
		border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? palette.danger : palette.grey)};
	}
	&::placeholder {
		color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.black}`)};
		opacity: 0.5; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.black}`)};
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: ${palette.grey};
	}
`

const Icon = styled(Flexed)`
	position: absolute;
	top: 0.063rem;
	bottom: 0.063rem;
	margin: auto;
	right: 0.063rem;
	width: 2.8rem;
	background: ${palette.white};
	border-bottom-right-radius: 9px;
	border-top-right-radius: 9px;
`
const StyledIcon = styled(Flexed)`
	position: absolute;
	top: 0.063rem;
	bottom: 0.063rem;
	margin: auto;
	left: 0.063rem;
	width: 2.5rem;
	background: ${palette.white};
	border-bottom-left-radius: 9px;
	border-top-left-radius: 9px;
`

const Eye = styled(BsFillEyeFill)`
	font-size: 1.25rem;
	color: ${palette.gray};
	opacity: 0.5;
	cursor: pointer;
`
const CloseEye = styled(BsFillEyeSlashFill)`
	font-size: 1.25rem;
	color: ${palette.gray};
	opacity: 0.5;
	cursor: pointer;
`

const TextArea = styled.textarea`
	font-family: 'Public Sans';
	width: 100%;
	/* line-height: 2rem; */
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	border-radius: 0.375rem;
	padding: 0.5rem 0.8rem;
	border: 1px solid ${({error, disabled}) => (disabled ? `${palette.lightGrey}` : error ? `${palette.danger}` : `${palette.customGrey}`)};

	color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.black}`)};
	width: 100%;
	// cursor: ${({disabled}) => (disabled ? `no-drop` : `pointer`)};
	background: ${({disabled, bgTransparent}) => (bgTransparent ? 'transparent' : disabled ? `${palette.grey}` : `${palette.white}`)};

	// &:hover {
	// 	box-shadow: 0 0 0.31rem ${({error, disabled}) => (disabled ? 'none' : error ? `${palette.danger}` : 'rgba(0, 0, 0, 0.25)')};
	// }
	&:focus {
		border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? palette.danger : palette.Btn_dark_green)};
	}
	&::placeholder {
		color: ${({disabled, isDarkTheme}) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
		opacity: 0.5; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({disabled, isDarkTheme}) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		// color: ${palette.black};
	}
`

const Search = styled.img`
	width: 1.5rem;
	height: 1.5rem;
`

export default InputField
