import React, {useState} from 'react'
import styled from 'styled-components'
import {palette} from '../../styles/colors'
import {Flexed, Text} from '../../styles/shared'
import {BsFillEyeFill, BsFillEyeSlashFill} from 'react-icons/bs'
import {IoSend} from 'react-icons/io5'
import {IoAttach} from 'react-icons/io5'

const InputSearch = ({handleImageChange, label, value, required, type, placeholder, handleChange, disabled, error, errorMsg, bgTransparent}) => {
	const [visible, setVisible] = useState(false)

	return (
		<>
			{label && (
				<Label type="normal" margin="0rem 0rem 0.19rem 0rem">
					{label} {required ? <Mandatory>*</Mandatory> : ''}
				</Label>
			)}

			<InputWrapper>
				<TextInput value={value} type="search" disabled={disabled} placeholder={placeholder} error={error} onChange={(event) => handleChange(event.target.value)} bgTransparent={bgTransparent} />
				<CustomIcon direction="row" align="center" justify="center">
					<Search src="/icons/search.svg" />
				</CustomIcon>
			</InputWrapper>
			{required && error && !disabled && (
				<Text fontSize={0.625} type="small" color="danger" textTransform="lowercase">
					{errorMsg}
				</Text>
			)}
		</>
	)
}

const Label = styled(Text)`
	/* font-weight: 500; */
	text-transform: capitalize;
	color: ${palette.black};
`

const Mandatory = styled.span`
	color: ${palette.black};
`

const InputWrapper = styled.div`
	position: relative;
`

const TextInput = styled.input`
	font-family: 'Public Sans';
	width: 100%;
	/* line-height: 2rem; */
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 1.25rem;
	border-radius: 0.675rem;
	padding: ${({type}) => (type === 'search' ? ' 0.5rem 2.8rem 0.5rem 0.8rem' : ' 0.5rem 0.8rem')};
	border: 1px solid ${({error, disabled}) => (disabled ? `${palette.lightGrey}` : error ? `${palette.danger}` : `${palette.yellow}`)};
	color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.white}`)};
	background: ${({disabled, bgTransparent}) => (bgTransparent ? 'transparent' : disabled ? `${palette.white}` : `${palette.yellow}`)};
	cursor: ${({disabled}) => (disabled ? `no-drop` : `pointer`)};
	width: 100%;

	&:focus {
		border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? palette.danger : palette.grey)};
	}
	&::placeholder {
		color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.white}`)};
		/* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({disabled}) => (disabled ? `${palette.grey}` : `${palette.white}`)};
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: ${palette.white};
	}
`

const CustomIcon = styled(Flexed)`
	position: absolute;
	top: 0.063rem;
	bottom: 0.063rem;
	margin: auto;
	right: 0.2rem;
	width: 2.5rem;
	background: ${palette.yellow};
	border-bottom-right-radius: 0.675rem;
	border-top-right-radius: 0.675rem;
`

const Search = styled.img`
	width: 1.5rem;
	color: ${palette.white};
`

export default InputSearch
