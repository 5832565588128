import React from 'react'
import styled from 'styled-components'
import {palette} from '../../styles/colors'

const Button = ({ifClicked, disabled, hover, textTransformation, borderRadius, label, icon, type, stretchMobile, stretchWeb, hasShadow, hasBorder, medium, small, margin, width, tooltip, color, fontWeight}) => {
	return (
		<Btn
			type={type}
			color={color}
			hasLabel={label}
			disabled={disabled}
			hover={hover}
			onClick={ifClicked}
			medium={medium}
			small={small}
			hasBorder={hasBorder}
			borderRadius={borderRadius}
			margin={margin}
			width={width}
			stretchMobile={stretchMobile}
			stretchWeb={stretchWeb}
			textTransformation={textTransformation}
			fontWeight={fontWeight}
			hasShadow={hasShadow}>
			{icon && <Icon color={color} hasLabel={label} src={`/icons/${icon}`} />}
			{label}
		</Btn>
	)
}

const handleColorType = (type) => {
	switch (type) {
		case 'grey':
			return palette.grey
		default:
			return palette.white
	}
}

const handleBackgroundType = (type) => {
	switch (type) {
		case 'transparent':
			return 'transparent'
		case 'grey':
			return 'transparent'
		case 'black':
			return palette.black
		default:
			return palette.black
	}
}

const handleBorderColor = (type) => {
	switch (type) {
		case 'black':
			return palette.black
		default:
			return palette.black
	}
}

const handleHoverBackgroundType = (type) => {
	switch (type) {
		case 'black':
			return palette.white
		case 'grey':
			return palette.black
		case 'transparent':
			return palette.black
		default:
			return palette.black
	}
}
const handleHoverColorType = (type) => {
	switch (type) {
		case 'black':
			return palette.black
		case 'grey':
			return palette.white
		case 'transparent':
			return palette.white
		default:
			return palette.white
	}
}

const Btn = styled.button`
	opacity: ${({disabled}) => (disabled ? '0.5' : '1')};
	text-transform: ${({textTransformation}) => (textTransformation ? 'uppercase' : 'capitalize')};
	display: inline-block;
	font-size: ${({small, medium}) => (small ? '1rem' : medium ? '1.125rem' : '1.25rem')};
	height: ${({small, medium}) => (small ? '2.375rem' : medium ? '3.375rem' : '3.75rem')};
	border-radius: ${({borderRadius}) => (borderRadius ? `${borderRadius}` : '5px')};
	border: ${({hasBorder, type}) => `0.063rem solid ${handleBorderColor(type)}`};
	padding: 0.75rem 0.75rem;
	color: ${({hasBorder, type}) => (hasBorder ? palette[type] : handleColorType(type))};
	background: ${({type, hasBorder}) => (hasBorder ? `transparent` : `${handleBackgroundType(type)}`)};
	margin: ${({margin}) => `${margin}`};
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '600')};
	opacity: ${({type}) => (type === 'disable' ? '50%' : '')};
	cursor: ${({disabled}) => (disabled ? 'no-drop' : 'pointer')};
	/* min-width: 5.125rem; */
	min-width: ${({width}) => (width ? width : 'min-content')};
	/* line-height: ${({width}) => (width ? width : 'min-content')}; */
	white-space: nowrap;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	transition: all ease 0.25s;
	font-family: 'Public Sans';

	&:hover {
		background-color: ${({hover, type}) => hover && type && handleHoverBackgroundType(type)};
		color: ${({hover, type}) => hover && type && handleHoverColorType(type)};
	}
`

const Icon = styled.img`
	width: 1.5rem;
	height: 1.5rem;
	/* color: ${palette.yellow}; */
	margin-right: ${({hasLabel}) => (hasLabel ? ` 0.5rem` : 'none')};
`

Btn.defaultProps = {
	type: 'primary',
	ifClicked: () => null
}

export default Button
