import React from 'react'
import {MDBDataTable, MDBIcon} from 'mdbreact'
import {FaArrowRight} from 'react-icons/fa' // Assuming you're using Font Awesome icons
import {palette} from '../../styles/colors'
import {styled} from 'styled-components'
import CustomPagination from '../CustomPagination'
import {Spacer} from '../../styles/shared'

const ProductsTable = ({setPageNumber, totalNumberOfProducts, pageNumber, pagesAmount, productsData}) => {
	const data = {
		columns: [
			{
				label: '',
				field: 'image'
				// width: 150
			},

			{
				label: 'Name',
				field: 'name'
				// width: 150
			},
			{
				label: 'PID',
				field: 'pid'
				// width: 150
			},
			{
				label: 'Description',
				field: 'description'
				// width: 250
			},
			{
				label: 'Qty',
				field: 'qty'
				// width: 40
			},
			{
				label: 'Retail Price',
				field: 'retailPrice'
				// width: 40
			},
			{
				label: 'Status',
				field: 'status'
				// width: 20
			},
			{
				label: 'Actions',
				field: 'icon'
				// width: 10
			}
		],
		rows: productsData
	}
	const paginationIcons = [
		<MDBIconCustom
			onClick={() => {
				setPageNumber(pageNumber - 1)
			}}
			icon="angle-left"
			size="lg"
		/>,
		<MDBIconCustom
			onClick={() => {
				setPageNumber(pageNumber + 1)
			}}
			icon="angle-right"
			size="lg"
		/>
	]

	return (
		<>
			<MDBDataTable paginationLabel={paginationIcons} displayEntries={false} responsive responsiveSm noBottomColumns={true} hover small data={data} sortable={false} searching={false} />
			<Spacer height={1.3} />
			<CustomPagination tableData={productsData} totalData={totalNumberOfProducts} pagesAmount={pagesAmount} pageNumber={pageNumber} setPageNumber={setPageNumber} />
		</>
	)
}

const MDBIconCustom = styled(MDBIcon)`
	background: ${palette.black};
	padding: 0 !important;
	width: 34.19px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 37.03px;
	color: ${palette.yellow};
`

export default ProductsTable
