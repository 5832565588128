import './App.css'
import {Route, Routes} from 'react-router-dom'
import RenderedComponents from './pages/RenderedComponents'
import {styled} from 'styled-components'
import PageLayout from './components/PageLayout'
import DashBoard from './pages/DashBoard'
import Customers from './pages/Customers'
import Campaign from './pages/Campaign'
import Orders from './pages/Orders'
import Settings from './pages/Settings'
import Feedbacks from './pages/Feedbacks'
import Products from './pages/Products'
import SalesPerson from './pages/SalesPerson'
import Banners from './pages/Banners'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import NewPassword from './pages/NewPassword'
import BacktoLogin from './pages/BacktoLogin'
import Otp from './pages/Otp'
import Loader from './components/common/Loader'
import {useSelector} from 'react-redux'
import ProtectedRoutes from './components/ProtectedRoutes'
import {useEffect} from 'react'

function App() {
	const loading = useSelector((state) => state.auth.isLoading)
	let isUserLogIn = sessionStorage.getItem('authorization')
	// let isUserLogIn = true

	useEffect(() => {
		isUserLogIn = sessionStorage.getItem('authorization')
	}, [sessionStorage.getItem('authorization')])
	// const isUserLogIn = true

	return (
		<>
			<Wrapper>
				<PageLayout>
					<Routes>
						<Route
							path="/dashboard"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<DashBoard />
								</ProtectedRoutes>
							}></Route>
						<Route path="/" element={<Login />}></Route>
						<Route path="/forgotpassword" element={<ForgotPassword/>}></Route>
						<Route path="/newpassword" element={<NewPassword/>}></Route>
						<Route path="/backtologin" element={<BacktoLogin />}></Route>
						<Route path="/enterotp" element={<Otp />}></Route>
						<Route
							path="/Customers"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<Customers />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Products"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<Products />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/SalesPerson"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<SalesPerson />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Banners"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<Banners />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Campaign"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<Campaign />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Orders"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<Orders />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Feedbacks"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<Feedbacks />
								</ProtectedRoutes>
							}></Route>
						<Route
							path="/Settings"
							element={
								<ProtectedRoutes isLoggedIn={isUserLogIn}>
									<Settings />
								</ProtectedRoutes>
							}></Route>
					</Routes>
				</PageLayout>
			</Wrapper>
			<Loader visible={loading} />
		</>
	)
}

const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`

export default App
