import React, {useEffect, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import InputField from './common/InputField'
import {Flexed, Heading, Spacer} from '../styles/shared'
import Button from './common/Button'
import SuccessModal from './modals/SuccessModal'
import {addCustomer} from '../api/apis'
import {setIsLoading} from '../actions/authActions'
import {useDispatch} from 'react-redux'
import {toastError, toastSuccess} from '../styles/toasterStyle'

const AddCustomer = ({onClose, getCustomer}) => {
	const [customerName, setCustomerName] = useState('')
	const [customerEmail, setCustomerEmail] = useState('')
	const [compnayName, setCompnayName] = useState('')
	const [compnayEmail, setCompnayEmail] = useState('')
	const [salesPersonCode, setSalesPersonCode] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [deliveryAddress, setDeliveryAddress] = useState('')
	const [cityName, setCityName] = useState('')
	const [stateName, setStateName] = useState('')
	const [zipCode, setZipCode] = useState('')

	const [customerNameError, setCustomerNameError] = useState('')
	const [customerEmailError, setCustomerEmailError] = useState('')
	const [compnayNameError, setCompnayNameError] = useState('')
	const [compnayEmailError, setCompnayEmailError] = useState('')
	const [salesPersonCodeError, setSalesPersonCodeError] = useState('')
	const [phoneNumberError, setPhoneNumberError] = useState('')
	const [deliveryAddressError, setDeliveryAddressError] = useState('')
	const [cityNameError, setCityNameError] = useState('')
	const [stateNameError, setStateNameError] = useState('')
	const [zipCodeError, setZipCodeError] = useState('')
	const [openSuccessModal, setOpenSuccessModal] = useState(false)

	const _dispatch = useDispatch()

	const doAddCustomer = async () => {
		_dispatch(setIsLoading(true))

		const response = await addCustomer(customerName, customerEmail, compnayName, compnayEmail, salesPersonCode, phoneNumber, deliveryAddress, cityName, stateName, zipCode)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			// toastSuccess(response?.message)
			setOpenSuccessModal(true)
			// onClose()
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const checkValidationOnClick = () => {
		let isValid = true
		if (customerName === '') {
			setCustomerNameError('name is required')
			isValid = false
		}
		if (customerEmail === '') {
			setCustomerEmailError('email is required')
			isValid = false
		}
		if (compnayName === '') {
			setCompnayNameError('company Name is required')
			isValid = false
		}
		if (compnayEmail === '') {
			setCompnayEmailError('company email is required')
			isValid = false
		}
		if (salesPersonCode === '') {
			setSalesPersonCodeError('sales person code is required')
			isValid = false
		}
		if (deliveryAddress === '') {
			setDeliveryAddressError('address is required')
			isValid = false
		}
		if (phoneNumber === '') {
			setPhoneNumberError('phone number is required')
			isValid = false
		}
		if (cityName === '') {
			setCityNameError('city name is required')
			isValid = false
		}
		if (stateName === '') {
			setStateNameError('state name is required')
			isValid = false
		}
		if (zipCode === '') {
			setZipCodeError('zip code is required')
			isValid = false
		}

		return isValid
	}
	return (
		<>
			{/* <Wrapper> */}

			<Form>
				<Heading color="black" fontSize={1.5}>
					Add Customer
				</Heading>
				<Spacer height={2.125} />
				<Row>
					<Col md={12} lg={6}>
						<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Enter Your Name"
							placeholder="Name"
							value={customerName}
							handleChange={(e) => {
								setCustomerNameError('')
								if (e === '') {
									setCustomerNameError('name is required')
								}
								setCustomerName(e)
							}}
							error={customerNameError}
							errorMsg={customerNameError}
							required
						/>

					</Col>
					<Col md={12} lg={6}>
						<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Enter Your Email"
							placeholder="Email"
							type="email"
							value={customerEmail}
							handleChange={(e) => {
								setCustomerEmailError('')

								const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
								if (e && e.match(isValidEmail)) {
									setCustomerEmailError('')
									setCustomerEmail(e)
								} else {
									setCustomerEmail(e)
									setCustomerEmailError('email is not correct')
								}
								if (e === '') {
									setCustomerEmailError('email is required')
								}
							}}
							error={customerEmailError}
							errorMsg={customerEmailError}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
				<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Enter Company Name"
							placeholder="Company Name"
							value={compnayName}
							handleChange={(e) => {
								setCompnayNameError('')
								if (e === '') {
									setCompnayNameError('company name is required')
								}
								setCompnayName(e)
							}}
							error={compnayNameError}
							errorMsg={compnayNameError}
							required
						/>
					</Col>
					<Col lg={6}>
					<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Enter Company Email"
							placeholder="Company Email"
							value={compnayEmail}
							handleChange={(e) => {
								setCompnayEmailError('')

								const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
								if (e && e.match(isValidEmail)) {
									setCompnayEmailError('')
									setCompnayEmail(e)
								} else {
									setCompnayEmail(e)
									setCompnayEmailError('email is not correct')
								}
								if (e === '') {
									setCompnayEmailError('company email is required')
								}
							}}
							
							error={compnayEmailError}
							errorMsg={compnayEmailError}
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
				<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Sales Person Code"
							placeholder="Code"
							allowOnlyNumbers
							value={salesPersonCode}
							handleChange={(e) => {
								setSalesPersonCodeError('')
								if (e === '') {
									setSalesPersonCodeError('sales person code is required')
								}
								setSalesPersonCode(e)
							}}
							error={salesPersonCodeError}
							errorMsg={salesPersonCodeError}
							required
						/>
					</Col>
					<Col lg={6}>
					<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Enter Phone Number"
							placeholder="Phone Number"
							value={phoneNumber}
							handleChange={(e) => {
								setPhoneNumberError('')
								if (e === '') {
									setPhoneNumberError('phone number is required')
								}
								setPhoneNumber(e)
							}}
							error={phoneNumberError}
							errorMsg={phoneNumberError}
							maxLength={11}
							allowPhoneNumberOnly
							required
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
				<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Enter Delivery Address"
							placeholder="Address"
							value={deliveryAddress}
							handleChange={(e) => {
								setDeliveryAddressError('')
								if (e === '') {
									setDeliveryAddressError('addres is required')
								}
								setDeliveryAddress(e)
							}}
							error={deliveryAddressError}
							errorMsg={deliveryAddressError}
							required
					
						/>
					</Col>
					<Col lg={6}>
					<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Enter City"
							placeholder="Enter City"
							value={cityName}
							handleChange={(e) => {
								setCityNameError('')
								if (e === '') {
									setCityNameError('city is required')
								}
								setCityName(e)
							}}
							error={cityNameError}
							errorMsg={cityNameError}
							required
						
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
				<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Enter State"
							placeholder="State"
							value={stateName}
							handleChange={(e) => {
								setStateNameError('')
								if (e === '') {
									setStateNameError('state is required')
								}
								setStateName(e)
							}}
							error={stateNameError}
							errorMsg={stateNameError}
							required
						/>
					</Col>
					<Col lg={6}>
					<Spacer height={1} />
						<InputField
							labelType="xlarge"
							label="Enter Zip Code"
							placeholder="Code"
							value={zipCode}
							handleChange={(e) => {
								setZipCodeError('')
								if (e === '') {
									setZipCodeError('zip code is required')
								}
								setZipCode(e)
							}}
							error={zipCodeError}
							errorMsg={zipCodeError}
							allowOnlyNumbers
							required
						/>
					</Col>
				</Row>
				<Spacer height={3.175} />
				<Row justifyContent="around">
					<Col lg={2.5}>
						<Button
							label="Cancel"
							hasBorder
							ifClicked={() => {
								onClose()
							}}
							width="100%"
						/>
						<Spacer height={1} />
					</Col>
					<Col lg={2.5}>
						<Button
							label="Submit"
							ifClicked={() => {
								if (checkValidationOnClick()) doAddCustomer()
							}}
							width="100%"
						/>
					</Col>
				</Row>
			</Form>
			{openSuccessModal && (
				<SuccessModal
					onClose={() => {
						getCustomer()
						onClose()
						setOpenSuccessModal(false)
					}}
					// onSubmit={() => {
					// 	getCustomer()
					// 	onClose()
					// 	setOpenSuccessModal(false)
					// }}
					label="New user add successfully"
				/>
			)}
			{/* </Wrapper> */}
		</>
	)
}

const Form = styled.div`
	background-color: ${palette.white};
	border-radius: 10px;
	${media.xs`
  padding: 1rem 1rem;
  `}
	${media.sm`
  padding: 1rem 1rem;
  `}
  ${media.md`
  padding: 2.625rem 5rem 2.625rem 5rem;
  `}
`
const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	height: 100vh;
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const TableWrapper = styled.div`
	border-radius: 0.625rem;
	background-color: ${palette.white};
	padding: 0.75rem;
`

const IconWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Icon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
`
export default AddCustomer
