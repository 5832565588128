import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {palette} from '../styles/colors'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Flexed, Spacer, Text} from '../styles/shared'
import InputField from '../components/common/InputField'
import Button from '../components/common/Button'
import {setIsLoading, setUserName} from '../actions/authActions'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {toastError, toastSuccess} from '../styles/toasterStyle'
import {logInApi} from '../api/apis'

const Login = () => {
	// const router = useRouter()
	const [emailAddress, setEmailAddress] = useState('')
	const [emailAddressError, setEmailAddressError] = useState('')
	let isUserLogIn = sessionStorage.getItem('authorization')

	const [password, setPassword] = useState('')
	const [passwordError, setPasswordError] = useState('')
	const _navigate = useNavigate()

	const _dispatch = useDispatch()

	useEffect(() => {
		if (isUserLogIn) _navigate('/dashboard')
	}, [])
	// doLogIn
	const doLogIn = async () => {
		_dispatch(setIsLoading(true))
		const response = await logInApi(emailAddress, password)
		console.log('response', response)
		if (response?.success) {
			sessionStorage.setItem('authorization', response?.access_tokens)
			sessionStorage.setItem('userName', response?.data?.username)
			_dispatch(setUserName(response?.data?.username))
			_navigate('/dashboard')
			toastSuccess('signIn Succeessfully')
		} else {
			toastError(response?.error)
		}
		_dispatch(setIsLoading(false))
	}
	const checkValidationOnClick = () => {
		let isValid = true
		if (emailAddress === '') {
			setEmailAddressError('email is required')
			isValid = false
		}
		if (password === '') {
			setPasswordError('password is required')
			isValid = false
		}

		return isValid
	}
	return (
		<>
			{!isUserLogIn && (
				<CustomContainer fluid>
					<RowWrapper justifyContent="center" alignItems="center">
						<Wrapper>
							<ColWrapper lg={8}>
								<Flexed direction="column" justify="space-evenly" width={`70%`}>
									<Flexed justify="center">
										<Logo src="/icons/login.png" alt="logo" />
									</Flexed>

									<Flexed direction="column">
										<Text fontSize={2.5} fontWeight="800" color="black">
											Login
										</Text>

										<Spacer height={2} />

										<InputField
											labelType="large"
											value={emailAddress}
											label="Email"
											placeholder=""
											// handleChange={(e) => {
											// 	setEmailAddressError('')
											// 	if (e === '') {
											// 		setEmailAddressError('Email is required')
											// 	}
											// 	setEmailAddress(e)
											// }}
											handleChange={(e) => {
												setEmailAddressError('')

												const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
												if (e && e.match(isValidEmail)) {
													setEmailAddressError('')
													setEmailAddress(e)
												} else {
													setEmailAddress(e)
													setEmailAddressError('Email is not correct')
												}
												if (e === '') {
													setEmailAddressError('Email is required')
												}
											}}
											error={emailAddressError}
											errorMsg={emailAddressError}
											required
										/>

										<Spacer height={1} />

										<InputField
											labelType="large"
											value={password}
											label="Password"
											type="password"
											placeholder="Password"
											handleChange={(e) => {
												setPasswordError('')
												if (e === '') {
													setPasswordError('password is required')
												}
												setPassword(e)
											}}
											error={passwordError}
											errorMsg={passwordError}
											required
										/>

										<Spacer height={1} />

										<Flexed justify="end">
											<Text color="yellow" fontWeight="400" fontSize="0.875">
												<StyleLink to="/forgotpassword">Forgot Password?</StyleLink>
											</Text>
										</Flexed>

										<Spacer height={1} />
										<Button
											width="100%"
											ifClicked={() => {
												if (checkValidationOnClick()) doLogIn()
											}}
											label="Login"
										/>
									</Flexed>
								</Flexed>
							</ColWrapper>
							{/* <ImageWrapper> */}
							<CustomhideCol lg={4}>
								<Image src="/icons/getstarted.png" />
							</CustomhideCol>
							{/* </ImageWrapper> */}
						</Wrapper>
					</RowWrapper>
				</CustomContainer>
			)}
		</>
	)
}

const CustomCol = styled(Col)`
	display: flex;

	gap: 20px;
`
const CustomhideCol = styled(Col)`
	${media.xs`
display:none;
`}
	${media.sm`
display:none;
`}
${media.md`
display:none;
`}
${media.lg`
padding:0px;
display:block;
height: 80vh;
width: 100%;
border-radius: 0rem 2rem 2rem 0rem;
`}
`
const ImageWrapper = styled.div`
	${media.xs`
display:none;
`}
	${media.sm`
display:none;
`}
${media.md`
display:none;
`}
${media.lg`

display:block;
height: 90vh;
width: 100%;
border-radius: 0rem 2rem 2rem 0rem;
`}
`

const Image = styled.img`
	height: 80vh;
	width: 100%;
	border-radius: 0rem 2rem 2rem 0rem;
`
const Logo = styled.img`
	width: 24%;
`

const CustomContainer = styled(Container)`
	background-color: ${palette.black};
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center; /* Center horizontally */
	align-items: center; /* Center vertically */
`
const StyleLink = styled(Link)`
	text-decoration: none !important;
	color: inherit !important;
`
const RowWrapper = styled(Row)`
	width: 100%;

	margin-left: 0px !important;
`

const ColWrapper = styled(Col)`
	display: flex;

	justify-content: center; /* Center horizontally */
`

const Wrapper = styled.div`
	width: 85%;
	height: 80vh;
	background-color: ${palette.white};
	border-radius: 2.106rem;
	display: flex;
`
export default Login
