import React, {useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {Flexed, Spacer, Text} from '../styles/shared'
import SearchSection from '../components/common/SearchSection'
import {palette} from '../styles/colors'
import Box from '../components/common/Box'
import DropDown from '../components/common/DropDown'
import StatsChart from '../components/StatsChart'

const DashBoard = () => {
	const [durationOptions, setDurationOptions] = useState([
		{label: 'Daily', value: 1},
		{label: 'Monthly', value: 2},
		{label: 'All time', value: 3}
	])

	const loop = [
		'A new User is Registered.',
		'You got a feedback from Mike Jackson',
		'A new User is Registered.',
		'You got a feedback from Mike Jackson',
		'A new User is Registered.',
		'You got a feedback from Mike Jackson',
		'A new User is Registered.'
	]
	return (
		<Wrapper>
			<Container fluid>
				<SearchSection title="Dashboard" />
				<Spacer height={1} />
				<Row>
					<Col xl={3} lg={4} md={6} sm={6}>
						<Spacer height={1} />
						<Box text="Pending Orders" count="123" icon="/icons/threedots.svg" />
					</Col>
					<Col xl={3} lg={4} md={6} sm={6}>
						<Spacer height={1} />
						<Box text="Active Orders" count="120" icon="/icons/tick.svg" />
					</Col>
					<Col xl={3} lg={4} md={6} sm={6}>
						<Spacer height={1} />
						<Box text="Customer Request" count="113" icon="/icons/case.svg" />
					</Col>
					<Col xl={3} lg={4} md={6} sm={6}>
						<Spacer height={1} />
						<Box text="Active Customers" count="140" icon="/icons/profile.svg" />
					</Col>
				</Row>

				<Row>
					<Col xl={9}>
						<Spacer height={1.875} />

						<GraphWrapper>
							<Flexed direction="row" align="center" justify="space-between" flexWrap={`wrap`} gap={`1`}>
								<Flexed direction="row" align="center" gap={1}>
									<IconWrapper>
										<Icon src="/icons/statistics.svg" />
									</IconWrapper>
									<Text type="xlarge" color="black" fontWeight={600}>
										Statistics
									</Text>
								</Flexed>

								<DropDown
									styledHeight={6}
									// firstSelected={selectedEditProduct?.company?.company_name}
									hasChanged={(value) => {
										// setSelectedDueDateError('')
										// if (value == '') {
										// 	setSelectedDueDateError('due is Required')
										// }
										// setSelectedDueDate(value)
									}}
									width="8"
									// required
									// error={selectedDueDateError}
									// errorMsg={selectedDueDateError}
									options={durationOptions}
								/>
							</Flexed>

							<Spacer height={3} />
							<ChartWrapper>
								<StatsChart />
								<Spacer height={2.75} />

								<Flexed flexWrap="wrap" direction="row" align="center" justify="space-around">
									<div>
										<Spacer height={1} />

										<CustomButton name="Pending Order"> <Circle/>&nbsp; Pending Order</CustomButton>
									</div>
									<div>
										<Spacer height={1} />

										<CustomButton name="Active Order"> <Circle/>&nbsp; Active Order</CustomButton>
									</div>
									{/* <div>
										<Spacer height={1} />

										<CustomButton name="Completed Order">Completed Order</CustomButton>
									</div> */}
								</Flexed>
							</ChartWrapper>
						</GraphWrapper>
					</Col>
					<Col xl={3}>
						<Spacer height={1.875} />
						<RecentActivities>
							<CustomFlexed direction="row" align="center" gap={1}>
								<IconWrapper>
									<Icon src="/icons/clock.svg" />
								</IconWrapper>
								<Text type="xlarge" color="black" fontWeight={600}>
									Recent Activity
								</Text>
							</CustomFlexed>
							<Spacer height={0.688} />
							<RecentActivitiesTextWrapper>
								{loop?.map((input) => {
									return (
										<>
											<RecentActivitiesText>
												<Text type="small" color="white">
													{input}
												</Text>
											</RecentActivitiesText>
											<Spacer height={0.625} />
										</>
									)
								})}
							</RecentActivitiesTextWrapper>
						</RecentActivities>
					</Col>
				</Row>
			</Container>
		</Wrapper>
	)
}

const CustomButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	color: ${palette.yellow};
	font-weight: 500;
	// width: 16.5rem;
	height: 68px;
	border-radius: 0.625rem;
	// background-color: ${({name}) => (name === 'Pending Order' ? 'white' : name === 'Active Order' ? 'white' : 'white')};

	`
const Circle=styled.div`
width:12px;
height:12px;
border-radius:50%;
background-color: ${palette.yellow};
`
const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const GraphWrapper = styled.div`
	padding: 1.375rem;
	background-color: ${palette.white};
	border-radius: 0.625rem;
`
const ChartWrapper = styled.div`
	padding: 1.25rem;
`

const IconWrapper = styled.div`
	width: 3.125rem;
	height: 3.125rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${palette.black};
	border-radius: 0.625rem;
`

const Icon = styled.img`
	width: 2.125rem;
	height: 2.125rem;
`

const RecentActivities = styled.div`
	background-color: ${palette.white};
	border-radius: 0.625rem;
	height: 96%;
`
const RecentActivitiesText = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	padding: 1rem 0.875rem;
`
const RecentActivitiesTextWrapper = styled.div`
	padding: 0.5rem;
`

const CustomFlexed = styled(Flexed)`
	padding: 1.375rem;
`
export default DashBoard
