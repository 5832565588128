import React, { useState } from "react";
import styled from "styled-components";
import { palette } from "../styles/colors";
import { Container, Row, Col, media } from "styled-bootstrap-grid";
import { Flexed, Spacer, Text } from "../styles/shared";
import InputField from "../components/common/InputField";
import Button from "../components/common/Button";
import {useDispatch} from 'react-redux'
import {setEmail, setIsLoading} from '../actions/authActions'
import {toastError, toastSuccess} from '../styles/toasterStyle'
import { forgetPassword } from "../api/apis";
import {useNavigate} from 'react-router-dom'

const ForgotPassword = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const _navigate = useNavigate()

	const _dispatch = useDispatch()
  const handleForget = async () => {

    _dispatch(setIsLoading(true))
    _dispatch(setEmail(emailAddress))

      const response = await forgetPassword(emailAddress);
      console.log("response", response);
      if (response?.success) {
        // sessionStorage.setItem(
        //   "authorization",
        //   response?.data?.admin_details?.auth_token
        // );
        // sessionStorage.setItem(
        //   "fullName",
        //   response?.data?.admin_details?.fullName
        // );

        console.log("toastSuccess>>>>?????????", toastSuccess);
        _navigate('/enterotp')
        toastSuccess(response?.message);

        // router.push("/otp");
        // Router.push({
        //   pathname: '/Enterotp',
        //   query: { prop: emailAddress},
        // });
        } else {
        toastError(response?.message);
      }
      _dispatch(setIsLoading(false))
    } 

const checkValidationOnClick = () => {
  let isValid = true
  if (emailAddress === '') {
    setEmailAddressError('email address is required')
    isValid = false
  }
 
 
 
  return isValid
}
  return (
    <>
     <CustomContainer fluid>
   
        <RowWrapper justifyContent="center"  alignItems="center" >
        
            <Wrapper>
              <ColWrapper lg={8} >
              <Flexed direction="column" justify="space-evenly" width={`70%`}>
       
              <Flexed justify="center">

             
                  <Logo src="/icons/questionmark.png" alt="logo" />
         
              </Flexed>
              
               <Flexed direction="column">

           
                    <Text fontSize={2.5} fontWeight="800" color="black" lineHeight="3">
                      Forgot Password
                    </Text>

                    <Spacer height={2} />

                    <InputField
                      labelType="large"
                      value={emailAddress}
                      label="Email"
                      placeholder=""
                      handleChange={(e) => {
                        setEmailAddressError("");
                        if (e === "") {
                          setEmailAddressError("Email is required");
                        }
                        setEmailAddress(e);
                      }}
                      error={emailAddressError}
                      errorMsg={emailAddressError}
                      required
                      />

                   

					
               
         
		

                    <Spacer height={2} />
				<Button
                 width="100%"
                 ifClicked={() => {
                  if(checkValidationOnClick())
                 handleForget()
                }}
                    label="Next"
                    />
	
		
  <Spacer height={2} />
            
				
                    </Flexed>
              
          </Flexed>
          </ColWrapper>
               {/* <ImageWrapper> */}
              <CustomhideCol lg={4}>
                   <Image src="/icons/getstarted.png" />
              </CustomhideCol>
			   {/* </ImageWrapper> */}
 
            </Wrapper>
        </RowWrapper>
    
      </CustomContainer>
                </>
  );
};

const CustomCol=styled(Col)`
display:flex;

gap:20px;
`
const CustomhideCol=styled(Col)`

${media.xs`
display:none;
`}
${media.sm`
display:none;
`}
${media.md`
display:none;
`}
${media.lg`
padding:0px;
display:block;
height: 80vh;
width: 100%;
border-radius: 0rem 2rem 2rem 0rem;
`}
`
const ImageWrapper = styled.div`

${media.xs`
display:none;
`}
${media.sm`
display:none;
`}
${media.md`
display:none;
`}
${media.lg`

display:block;
height: 90vh;
width: 100%;
border-radius: 0rem 2rem 2rem 0rem;
`}
`

const Image = styled.img`
   
  height: 80vh;
  width: 100%;
  border-radius: 0rem 2rem 2rem 0rem;


`;
const Logo = styled.img`
width:40%;
${media.xs`
width:75%;
`}
${media.sm`
width:45%;
`}
${media.md`
width:45%;
`}
${media.lg`
width:40%;
`}
`;


const CustomContainer = styled(Container)`
  background-color: ${palette.black};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
`;

const RowWrapper = styled(Row)`
  width: 100%;

 margin-left: 0px !important;

`;

const ColWrapper = styled(Col)`
  display: flex;

  justify-content: center; /* Center horizontally */
`;

const Wrapper = styled.div`
  width: 85%;
  height: 80vh;
  background-color: ${palette.white};
  border-radius: 2.106rem;
  display: flex;
`;
export default ForgotPassword;
