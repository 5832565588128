import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { palette } from "../../styles/colors";

import { Col, Container, Row, media } from "styled-bootstrap-grid";
import {
  Flexed,
  Heading,
  Spacer,
  Text,
  VerticalSpacer,
} from "../../styles/shared";
import CustomDropDown from "../common/CustomDropDown";
import Button from "../common/Button";
import DropDown from "../common/DropDown";
import CheckBox from "../common/CheckBox";
import Toggle from "../common/Toggle";
import CustomersTable from "../tables/CustomersTable";
import OrderDetailsTable from "../tables/OrderDetailsTable";
import OrderRefundModal from "./OrderRefundModal";
import CustomCheckBox from "../common/CustomCheckBox";

const OrdersDetailsModal = ({ onClose }) => {
  const [openRefundModal, setOpenRefundModal] = useState("");

  const [OrderDetails, setTableTest] = useState([
    {
      id: <CustomCheckBox height={1} />,
      productName: (
        <div
          onClick={() => {
            // setIsProfileViewModalOpen(true)
          }}
        >
          <Text type="medium" color="black" fontWeight={300}>
            UtoR Digital
          </Text>
        </div>
      ),
      qty: (
        <Text type="medium" color="black" fontWeight={300}>
          John Doe
        </Text>
      ),
      price: (
        <Text type="medium" color="black" fontWeight={300}>
          John Doe
        </Text>
      ),
      total: (
        <Text type="medium" color="black" fontWeight={300}>
          John Doe
        </Text>
      ),
    },

    {
      id: <CustomCheckBox height={1} />,
      productName: (
        <div
          onClick={() => {
            // setIsProfileViewModalOpen(true)
          }}
        >
          <Text type="medium" color="black" fontWeight={300}>
            UtoR Digital
          </Text>
        </div>
      ),
      qty: (
        <Text type="medium" color="black" fontWeight={300}>
          John Doe
        </Text>
      ),
      price: (
        <Text type="medium" color="black" fontWeight={300}>
          John Doe
        </Text>
      ),
      total: (
        <Text type="medium" color="black" fontWeight={300}>
          John Doe
        </Text>
      ),
    },

    {
      id: <CustomCheckBox height={1} />,
      productName: (
        <div
          onClick={() => {
            // setIsProfileViewModalOpen(true)
          }}
        >
          <Text type="medium" color="black" fontWeight={300}>
            UtoR Digital
          </Text>
        </div>
      ),
      qty: (
        <Text type="medium" color="black" fontWeight={300}>
          John Doe
        </Text>
      ),
      price: (
        <Text type="medium" color="black" fontWeight={300}>
          John Doe
        </Text>
      ),
      total: (
        <Text type="medium" color="black" fontWeight={300}>
          John Doe
        </Text>
      ),
    },
  ]);
  return (
    <>
      <Modal
        open={true}
        onClose={onClose}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
        showCloseIcon={false}
      >
        <ModalWrapper>
          <Container>
            <Row>
              <CustomCol lg={7}>
                <Wrapper>
                  <Heading color="black" fontSize={1.5}>
                    Order Details
                  </Heading>
                  <Spacer height={1.5} />
                
                  <Scroll height={15}>
                    <TableWrapper>
                      <OrderDetailsTable OrderDetails={OrderDetails} />
                    </TableWrapper>
                  </Scroll>
                </Wrapper>
                <Spacer height={3} />
                <CustomerWrapper>
                  <Heading color="black" fontSize={1.5}>
                    Paid by Customer
                  </Heading>
                  <Spacer height={1.5} />
                  <Billing>
                    <CustomFlex justify="space-between" align="end">
                      <CustomFlexed gap={4}>
                        <Text color="black" fontSize="1.5">
                          Subtotal
                        </Text>
                        <CustomText color="black" fontSize="1.5">
                          3 items
                        </CustomText>
                      </CustomFlexed>
                      <Text color="black" fontSize="1.5">
                        £21300
                      </Text>
                    </CustomFlex>

                    <Spacer height={1.5} />

                    <CustomFlex justify="space-between" align="end">
                      <CustomFlexed gap={4}>
                        <Text color="black" fontSize="1.5">
                          Subtotal
                        </Text>
                        <CustomText color="black" fontSize="1.5">
                          3 items
                        </CustomText>
                      </CustomFlexed>
                      <Text color="black" fontSize="1.5">
                        £21300
                      </Text>
                    </CustomFlex>

                    <Spacer height={1.5} />
                    <CustomFlex justify="space-between" align="end">
                      <CustomFlexed gap={4}>
                        <Text color="black" fontSize="1.5">
                          Subtotal
                        </Text>
                        <CustomText color="black" fontSize="1.5">
                          3 items
                        </CustomText>
                      </CustomFlexed>
                      <Text color="black" fontSize="1.5">
                        £21300
                      </Text>
                    </CustomFlex>
                    <Spacer height={2} />
                    <Total justify="space-between" align="end">
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Total paid by customer
                      </Text>
                      <Text color="black" fontSize="1.5" lineHeight={1.5}>
                        £21300
                      </Text>
                    </Total>
                  </Billing>
                </CustomerWrapper>
              </CustomCol>

              <CustomCol lg={5}>
                <Wrapper>
                  <Spacer height={0.5} />
                  <Heading color="black" fontSize={1.5}>
                    Customer Information
                  </Heading>
                  <Spacer height={1} />
                  <TableWrapper>
                    <Scroll height="37">
                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                      <Spacer height={2} />

                      <Text color="black" fontSize="1" lineHeight={1.5}>
                        Name
                      </Text>
                      <Text
                        color="black"
                        fontSize="1.5"
                        fontWeight={700}
                        lineHeight={1.5}
                      >
                        Shan Jeson
                      </Text>
                    </Scroll>
                  </TableWrapper>
                </Wrapper>
                <Spacer height={3} />
              </CustomCol>
            </Row>
            <Row justifyContent="end">
              <Col lg={3}>
                <Button
                  width="100%"
                  hasBorder
                  ifClicked={() => {
                    onClose();
                  }}
                  label="Cancel"
                />
              </Col>
              <Col lg={3}>
                <Button
                  width="100%"
                  ifClicked={() => {
                    setOpenRefundModal(true);
                  }}
                  label="Proceed Order"
                />
              </Col>
            </Row>
            {openRefundModal && (
              <OrderRefundModal
                onClose={() => {
                  setOpenRefundModal(false);
                }}
              />
            )}
          </Container>
        </ModalWrapper>
      </Modal>
    </>
  );
};
const HorizontalScrollContainer = styled.div`
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: none;
  white-space: nowrap; /* Prevent wrapping of content */
`;
const Scroll = styled.div`
  overflow-y: auto;

  height: ${({ height }) => `${height}rem`};

  &::-webkit-scrollbar {
    width: 0.3rem !important;
    height: 0.1rem !important;
  }

  &::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 1rem;
    border: 0.01rem solid ${palette.silver};
    border-width: 0rem 0.1rem 0rem 0rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${palette.yellow};
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Total = styled(Flexed)`
  border-top: 1px solid ${palette.customGrey};
  padding-top: 1.5rem;
  // ${media.xs`
// gap:10rem;
// `}
  // ${media.sm`
// gap:10rem;
// `}
  // ${media.md`

// `}
`;
const CustomerWrapper = styled.div``;

const Billing = styled.div`
  border: 1px solid ${palette.customGrey};
  border-radius: 0.5rem;
  padding: 1.5rem;
  ${media.xs`
  padding:.5rem .2rem;
`}
  ${media.sm`
padding:.5rem .2rem;
`}
${media.md`
padding:1.5rem;
`}
${media.lg`
padding:.5rem;
`}
`;
const Wrapper = styled.div`
  //   border: 1px solid ${palette.customGrey};
  border-radius: 0.5rem;
`;
const TableWrapper = styled.div`
  border-radius: 0.625rem;
  border: 1px solid ${palette.customGrey};
  background-color: ${palette.white};
  padding: 0.75rem;
`;
const CustomCol = styled(Col)``;

const CustomFlex = styled(Flexed)`
  padding: 0.5rem;
  // ${media.xs`
// gap:6rem;
// `}
  // ${media.sm`
// gap:6rem;
// `}
  // ${media.md`
// 
// `}
`;
const CustomFlexed = styled(Flexed)`
  display: flex;
  ${media.xs`
display:block;
`}
  ${media.sm`
display:block;
`}
${media.md`
display:flex;
`}
${media.lg`
display:flex;
`}
`;

const CustomText = styled(Text)`
  ${media.xs`
padding-top:15px;
`}
  ${media.sm`
padding-top:15px;
`}
${media.md`
padding-top:0px;
`}
${media.lg`
padding-top:0px;
`}
`;

const StatusHeading = styled(Heading)`
  display: flex;

  align-items: center;
  width: 100%;
  border-right: 1px solid #cccbcb;
  height: 7rem;
`;

const ProfilStatus = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
`;
const IconWrapper = styled.div`
  background-color: ${palette.black};
  border-radius: 0.625rem;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;
const CustomRow = styled(Row)`
  padding: 0% !important;

  gap: ${({ gap }) => `${gap}rem`};
`;

const InnetText = styled.span`
  font-size: 1.25rem;
  color: black;
`;
const Phone = styled(Text)``;
const Email = styled(Text)``;

const ModalWrapper = styled.div`
  padding: 1.125rem 0rem;
  height: 100%;
  background: ${palette.white};
`;
const Cross = styled.img`
  height: 0.85rem;
`;

const Image = styled.img`
  height: 9.25rem;
  border: 2px solid #f49c10;
  border-radius: 50%;
`;
const ProfileData = styled.div`
  border-bottom: 1px solid #cccbcb;
  width: 100%;
  //   height: 11.5rem;
`;

export default OrdersDetailsModal;
