import styled from 'styled-components'
import {palette} from './colors'

const handleHeadingFontSize = (level) => {
	switch (level) {
		case 'xlarge':
			return '1.375rem'
		case 'large':
			return '1.25rem'
		case 'medium':
			return '1.125rem'
		case 'normal':
			return '1rem'
		case 'small':
			return '0.875rem'
		case 'xsmall':
			return '0.75rem'
		default:
			return '0.75rem'
	}
}

const handleHeadingLineHeight = (level) => {
	switch (level) {
		case 'xlarge':
			return '1.375rem'
		case 'large':
			return '1.25rem'
		case 'medium':
			return '1.125rem'
		case 'normal':
			return '1rem'
		case 'small':
			return '0.875rem'
		case 'xsmall':
			return '0.75rem'
		default:
			return '0.75rem'
	}
}

// Heading Levels
//             fontSize
// xlarge    22px || 1.375rem
// large    20px || 1.25rem
// medium   18px || 1.125rem
// normal   16px || 1rem
// small    14px || 0.875rem
// xsmall   12px || 0.75rem

export const Heading = styled.div`
	font-family: ${({family}) => (family ? `${family}` : 'Public Sans')};
	text-transform: ${({textTransform}) => (textTransform ? textTransform : 'capitalize')};
	font-size: ${({level, fontSize}) => (fontSize ? `${fontSize}rem` : handleHeadingFontSize(level))};
	line-height: ${({level, lineHeight}) => (lineHeight ? `${lineHeight}rem` : handleHeadingLineHeight(level))};
	color: ${({color, isDarkTheme}) => (color ? palette[color] : palette.black)};
	margin: ${({margin}) => `${margin}`};
	text-align: ${({isCentered}) => (isCentered ? `center` : 'inherit')};
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : 600)};
	cursor: ${({pointer}) => (pointer ? `pointer` : '')};
	opacity: ${({opacity}) => (opacity ? opacity : '1')};
	letter-spacing: ${({letterSpacing}) => (letterSpacing ? `${letterSpacing}rem` : '')};
`

const handleParagraphFontSize = (type) => {
	switch (type) {
		case 'xlarge':
			return '1.375rem'
		case 'large':
			return '1.25rem'
		case 'medium':
			return '1.125rem'
		case 'normal':
			return '1rem'
		case 'small':
			return '0.875rem'
		case 'xsmall':
			return '0.75rem'
		default:
			return '0.75rem'
	}
}

const handleParagraphLineHeight = (type) => {
	switch (type) {
		case 'xlarge':
			return '1.375rem'
		case 'large':
			return '1.25rem'
		case 'medium':
			return '1.125rem'
		case 'normal':
			return '1rem'
		case 'small':
			return '0.875rem'
		case 'xsmall':
			return '0.75rem'
		default:
			return '0.75rem'
	}
}

// Paragraph Levels
//             fontSize
// xlarge    22px || 1.375rem
// large    20px || 1.25rem
// medium   18px || 1.125rem
// normal   16px || 1rem
// small    14px || 0.875rem
// xsmall   12px || 0.75rem

export const Text = styled.div`
	font-family: ${({family}) => (family ? `${family}` : 'Public Sans')};
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '400')};
	font-size: ${({type, fontSize}) => (fontSize ? `${fontSize}rem` : handleParagraphFontSize(type))};
	line-height: ${({type, lineHeight}) => (lineHeight ? `${lineHeight}rem` : handleParagraphLineHeight(type))};
	color: ${({color}) => (color ? palette[color] : palette.grey)};
	margin: ${({margin}) => `${margin}`};
	text-align: ${({isCentered}) => (isCentered ? `center` : 'left')};
	cursor: ${({pointer}) => (pointer ? `pointer` : '')};
	opacity: ${({opacity}) => (opacity ? opacity : '1')};
	text-transform: ${({textTransform}) => (textTransform ? textTransform : '')};
	letter-spacing: ${({letterSpacing}) => (letterSpacing ? `${letterSpacing}rem` : '')};
	/* white-space: wrap; */
	/* word-break: break-all; */
`

export const Divider = styled.div`
	height: 1px;
	width: 100%;
	background: ${({color}) => (color ? palette[color] : palette.footer_border)};
	margin: ${({margin}) => `${margin}`};
`

export const Spacer = styled.div`
	height: ${({height}) => `${height}rem`};
`

export const VerticalSpacer = styled.span`
	width: ${({width}) => `${width}rem`};
	display: inline-block;
`

export const Flexed = styled.div`
	display: ${({inline}) => (inline ? 'inline-flex' : 'flex')};
	// flex-direction: column;
	flex-direction: ${({direction}) => direction};
	align-items: ${({align}) => align};
	justify-content: ${({justify}) => justify};
	margin: ${({margin}) => `${margin}`};
	cursor: ${({pointer}) => `${pointer}`};
	gap: ${({gap}) => `${gap}rem`};
	width: ${({width}) => `${width}`};
	height: ${({height}) => `${height}`};
	flex-wrap: ${({flexWrap}) => (flexWrap ? `${flexWrap}` : '')};
`
