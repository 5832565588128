import React, {useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import {Flexed, Spacer, Text} from '../styles/shared'
import InputField from '../components/common/InputField'
import SuccessModal from '../components/modals/SuccessModal'
import Button from '../components/common/Button'
import GalleryModal from '../components/modals/GalleryModal'
const Settings = () => {
	const [fullName, setFullName] = useState('Shaheer Shahid')
	const [email, setEmail] = useState('shaheershahidkhan@gmail.com')
	const [password, setPassword] = useState('mai nai bataonga')
	const [newPassword, setNewPassword] = useState('mai nai bataonga')
	const [confirmPassword, setConfirmPassword] = useState('mai nai bataonga')

	const [profilImage, setProfileImage] = useState('')
	const [showImage, setShowImage] = useState(null)
	const [openSuccessModal, setOpenSuccessModal] = useState(false)
    const [openGalleryModal,setOpenGalleryModal] = useState('')
  

	const handleCapture = ({target}) => {
		const reader = new FileReader()
		reader.readAsDataURL(target.files[0])
		reader.onload = () => {
			if (reader.readyState === 2) {
				setShowImage(reader.result)
				setProfileImage(reader.result)
			}
		}
	}

	return (
		<Wrapper>
			<Container fluid>
				<SearchSection title="Settings" noSearch />
				<Spacer height={3.5} />
				<InfoWrapper>
					<Row>
						<InputWrapper   onClick={(e)=>{
									e.preventDefault();
									setOpenGalleryModal(true)
								  }} >
							<LabelWrapper>
								<Text type="small">Upload Image</Text>
							</LabelWrapper>

							<CustomUpload
								
							>
								<Icon src="icons/add.svg" />
								<FileInput
									id="faceImage"
									accept="image/jpeg/png"
									type="file"
									onChange={(e) => {
										handleCapture(e)
										setShowImage('')
									}}
								/>
							</CustomUpload>
							<Upload>
								{/* <UploadIcon showImage={showImage || profilImage} src={showImage ? showImage : profilImage ? process.env.REACT_APP_PUBLIC_IMAGE_URL + profilImage : '/camera.png'} /> */}
								<UploadIcon showImage={showImage || profilImage} src={showImage ? showImage : profilImage ? process.env.REACT_APP_PUBLIC_IMAGE_URL + profilImage : 'icons/inputfile.png'} />
								<FileInput
									id="faceImage"
									accept="image/jpeg/png"
									type="file"
									onChange={(e) => {
										handleCapture(e)
										setShowImage('')
									}}
								/>
							</Upload>
						</InputWrapper>
						<Col>
							<Spacer height={2.5} />

							<Row alignItems="center">
								<Col lg={3}>
									<Text type="xlarge" color="black" fontWeight={600}>
										Full Name
									</Text>
								</Col>

								<Col lg={6.5}>
									{/* <InputWrapper> */}
									<InputField
										value={fullName}
										// width={38.688}
										handleChange={(e) => {
											// setCompanyNameError('')
											// if (e === '') {
											// 	setCompanyNameError('company name is required')
											// }
											setFullName(e)
										}}
										required
										// error={companyNameError}
										// errorMsg={companyNameError}
										// label="Company Name"
										placeholder="Enter Full Name"
										type="text"
										icon="inputProfile"
										customIcon
									/>
								</Col>
							</Row>
						</Col>

						<Col>
							<Spacer height={2.5} />
							<Row alignItems="center">
								<Col lg={3}>
									<Text type="xlarge" color="black" fontWeight={600}>
										Your Email
									</Text>
								</Col>

								<Col lg={6.5}>
									{/* <InputWrapper> */}
									<InputField
										value={email}
										// width={38.688}
										handleChange={(e) => {
											// setCompanyNameError('')
											// if (e === '') {
											// 	setCompanyNameError('company name is required')
											// }
											setEmail(e)
										}}
										required
										// error={companyNameError}
										// errorMsg={companyNameError}
										// label="Company Name"
										placeholder="Enter Email"
										type="text"
										icon="email"
										customIcon
									/>
								</Col>
							</Row>
						</Col>
						<Col>
							<Spacer height={2.5} />

							<Row alignItems="center">
								<Col lg={3}>
									<Text type="xlarge" color="black" fontWeight={600}>
										Current Password
									</Text>
								</Col>

								<Col lg={6.5}>
									{/* <InputWrapper> */}
									<InputField
										value={password}
										// width={38.688}
										handleChange={(e) => {
											// setCompanyNameError('')
											// if (e === '') {
											// 	setCompanyNameError('company name is required')
											// }
											setPassword(e)
										}}
										required
										// error={companyNameError}
										// errorMsg={companyNameError}
										// label="Company Name"
										placeholder="Password"
										type="password"
										icon="password"
										customIcon
									/>
								</Col>
							</Row>
						</Col>
						<Col>
							<Spacer height={2.5} />

							<Row alignItems="center">
								<Col lg={3}>
									<Text type="xlarge" color="black" fontWeight={600}>
										New Password
									</Text>
								</Col>

								<Col lg={3.25}>
									{/* <InputWrapper> */}
									<InputField
										value={newPassword}
										// width={38.688}
										handleChange={(e) => {
											// setCompanyNameError('')
											// if (e === '') {
											// 	setCompanyNameError('company name is required')
											// }
											setNewPassword(e)
										}}
										required
										// error={companyNameError}
										// errorMsg={companyNameError}
										// label="Company Name"
										placeholder="New Password"
										type="password"
										icon="password"
										customIcon
									/>
								</Col>
								<Col lg={3.25}>
									{/* <InputWrapper> */}
									<InputField
										value={confirmPassword}
										// width={38.688}
										handleChange={(e) => {
											// setCompanyNameError('')
											// if (e === '') {
											// 	setCompanyNameError('company name is required')
											// }
											setConfirmPassword(e)
										}}
										required
										// error={companyNameError}
										// errorMsg={companyNameError}
										// label="Company Name"
										placeholder="confirm Password"
										type="password"
										icon="password"
										customIcon
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					{/* </InputWrapper> */}
					<Spacer height={2} />
					<Flexed direction="row" justify="center" align="center">
						<Button
							label="update"
							ifClicked={() => {
								setOpenSuccessModal(true)
							}}
							width="17.125rem"
						/>
					</Flexed>
				</InfoWrapper>
			</Container>

			{openSuccessModal && (
				<SuccessModal
					onClose={() => {
						setOpenSuccessModal(false)
					}}
					label="Your information update successfully"
		
				/>
			)}
			 {openGalleryModal && (
        <GalleryModal
          onClose={() => {
            setOpenGalleryModal(false);
          }}
          label="Upload Image"
		  setImage={setShowImage}
        />
      )}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	/* height: 100%; */
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const InfoWrapper = styled.div`
	padding: 1.688rem 4rem;
	border-radius: 20px;
	background-color: ${palette.white};
`

const InputWrapper = styled.div`
	position: relative;
	/* height: 8.5rem; */
`

const UploadIcon = styled.img`
	width: ${({showImage}) => (showImage ? '100%' : '3rem')};
	height: ${({showImage}) => (showImage ? '100%' : '3rem')};
	border-radius: 100%;
	z-index: 1;
	/* font-size: 2.5rem; */
	/* color: ${palette.black}; */
`
const Upload = styled.label`
	/* padding: 0.5rem; */
	height: 10rem;
	width: 10rem;
	border: 3px solid ${palette.yellow};
	border-radius: 100%;
	display: flex;
	background-color: ${palette.customGrey};
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
`
const CustomUpload = styled.label``
const FileInput = styled.input`
	display: none !important;
`
const LabelWrapper = styled.div`
	position: absolute;
	top: 8.5rem;
	left: 2rem;
	background-color: transparent;
	padding: 0 0.2rem;
`

const Icon = styled.img`
	position: absolute;
	top: 10.5rem;
	left: 4rem;
	width: 2rem;
	height: 2rem;
	z-index: 2;
	cursor: pointer;
`
export default Settings
