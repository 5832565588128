import axios from 'axios'
// import {toastError, toastSuccess} from '../styled/toastStyle'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
// var app = express();

// app.use(function(req, res, next) {
//   res.header("Access-Control-Allow-Origin", "*");
//   res.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
//   next();
// });

//Category

export const addCustomer = async (username, email, company_name, company_email, salespersoncode, phone_no, address, city, state, zipcode) => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/createCustomer/`, {
			username,
			email,
			companyName: company_name,
			companyEmail: company_email,
			salesPersonCode: salespersoncode,
			phoneNo: phone_no,
			address: address,
			city,
			state,
			zipCode: zipcode
		})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getCustomer = async (salesPersonCode, page, pageSize) => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(
			salesPersonCode === 0
				? `${process.env.REACT_APP_API_URL}admincrm/getAllCustomers/?page=${page}&pageSize=${pageSize}`
				: `${process.env.REACT_APP_API_URL}admincrm/getAllCustomers/?salesPersonCode=${salesPersonCode}&page=${page}&pageSize=${pageSize}`
		)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const getProducts = async (page, pageSize) => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllProducts/?page=${page}&pageSize=${pageSize}`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const getCustomerType = async () => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getCustomerType/`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getProductGroup = async () => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getProductGroup/`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const deleteCustomer = async (id) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.delete(`${process.env.REACT_APP_API_URL}admincrm/deleteCustomer?id=${id}`, config)
		.then((res) => {
			console.log('resasdasda', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getFeedBacks = async (page, pageSize) => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllFeedbacks/?page=${page}&pageSize=${pageSize}`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
//delete FeedBack
export const deleteFeedBack = async (id) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.delete(`${process.env.REACT_APP_API_URL}admincrm/deleteFeedback?id=${id}`, config)
		.then((res) => {
			console.log('resasdasda', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const logInApi = async (email, password) => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }

	let response = []
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/signin/`, {
			email: email,
			password: password
		})
		.then((res) => {
			console.log('res', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const forgetPassword = async (email) => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }

	let response = []
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/forgotPassword/`, {
			email: email
		})
		.then((res) => {
			console.log('res', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const verifyOtp = async (email, otp) => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }

	let response = []
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/verifyOtp/`, {
			email: email,
			otp: otp
		})
		.then((res) => {
			console.log('res', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const resetPassword = async (password, email) => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }

	let response = []
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/resetPassword/`, {
			password: password,
			email: email
		})
		.then((res) => {
			console.log('res', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateCustomerStatus = async (id, status) => {
	const token = sessionStorage.getItem('authorization')

	// console.log('status', id, status, selectedCustomerType, selectedProductGroupType)
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(`${process.env.REACT_APP_API_URL}admincrm/updateCustomer?id=${id}`, {status: status}, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const updateCustomer = async (id, selectedCustomerType, selectedProductGroupType, selectedStatusValue) => {
	const token = sessionStorage.getItem('authorization')

	// console.log('status', id, status, selectedCustomerType, selectedProductGroupType)
	const config = {
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(
			`${process.env.REACT_APP_API_URL}admincrm/updateCustomer?id=${id}`,
			{
				customer_type: selectedCustomerType,
				categories: selectedProductGroupType,
				status: selectedStatusValue
			},
			config
		)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

/// Products

//////////////////////////  Banner Apis /////////////////////////////

export const getAllBanners = async (page, pageSize) => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllBanners/`, {
			page: page,
			pageSize: pageSize
		})
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

///// canpaigns

export const addBanner = async (title, images, discount, status, endDate) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/createBanner/`, {
			title: title,
			images: images,
			discount: discount,
			status: status,
			endDate: endDate
		})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateBanner = async (bannerId, title, images, discount, status, endDate) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(`${process.env.REACT_APP_API_URL}admincrm/updateBanner/?id=${bannerId}`, {
			title: title,
			images: images,
			discount: discount,
			status: status,
			endDate: endDate
		})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateBannerWithoutImg = async (bannerId, title, discount, status, endDate) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(`${process.env.REACT_APP_API_URL}admincrm/updateBanner/?id=${bannerId}`, {
			title: title,
			//  images: images,
			discount: discount,
			status: status,
			endDate: endDate
		})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const deleteBanner = async (bannerId) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	try {
		const res = await axios.delete(`${process.env.REACT_APP_API_URL}admincrm/deleteBanner?id=${bannerId}`)
		console.log('response', res.data)
		response = res.data
	} catch (error) {
		console.error(error)
	}
	return response
}

/// Compaigns
export const getCompaigns = async (page, pageSize) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllCompaigns/?page=${page}&pageSize=${pageSize}`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const generateImageLink = async (image) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/upload/`, {base64: image})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const addCompaign = async (name, title, body, radio, image, email) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/createCompaign/`, {
			compaignName: name,
			compaignTitle: title,
			compaignBody: body,
			shareWith: radio === 'Email' ? '' : radio,
			shareWithEmail: radio === 'Email' ? email : [],
			imgUrl: image
		})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const deleteCampaign = async (id) => {
	const token = sessionStorage.getItem('authorization')
	const config = {
		headers: {
			authorization: `bearer ${token}`
		}
	}
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.delete(`${process.env.REACT_APP_API_URL}admincrm/deleteCompaign/?id=${id}`, config)
		.then((res) => {
			console.log('resasdasda', res)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

/////////////////////////////

export const getAllOrders = async (page, pageSize) => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllOrders/?page=${page}&pageSize=${pageSize}`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const syncProductGroups = async () => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/syncProductGroups/`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const processAndStoreData = async () => {
	// const token = sessionStorage.getItem('authorization')
	// const config = {
	// 	headers: {
	// 		authorization: `bearer ${token}`
	// 	}
	// }
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/processAndStoreData/`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateProductStatus = async (id, status) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(
			`${process.env.REACT_APP_API_URL}admincrm/updateProduct/?id=${id}`,
			{
				productAvailable: status
			},
			{
				timeout: 0 // Set timeout to 0 for no timeout
			}
		)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

/// sale person
export const getAllSalePerson = async (page, pageSize) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllSalesPerson/?page=${page}&pageSize=${pageSize}`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateSalesPersonStatus = async (Id, status) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(`${process.env.REACT_APP_API_URL}admincrm/updateSalesPerson/?id=${Id}`, {
			status: status
		})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const deleteSalesPerson = async (Id) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.delete(`${process.env.REACT_APP_API_URL}admincrm/deleteSalesPerson/?id=${Id}`)
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateSalesPerson = async (Id, customerType, status) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(`${process.env.REACT_APP_API_URL}admincrm/updateSalesPerson/?id=${Id}`, {
			customerType: customerType,
			status: status
		})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const addSalesPerson = async (fullName, email, salesPersonCode, phoneNumber) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/createSalesPerson/`, {
			fullName,
			email,
			salesPersonCode,
			phoneNumber
		})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateProductData = async (productId, updateData) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.patch(`${process.env.REACT_APP_API_URL}admincrm/updateProduct/?id=${productId}`, updateData, {
			timeout: 0 // Set timeout to 0 for no timeout
		})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const syncProducts = async (groupList, dataOptions) => {
	const {productName, productDesc, productQuantity, defaultSellPrice, distributerPrice, onlinePowerHousePrice, retailorPrice} = dataOptions
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.post(`${process.env.REACT_APP_API_URL}admincrm/syncProducts/`, {
			groupList,
			productName,
			productDesc,
			productQuantity,
			defaultSellPrice,
			distributerPrice,
			onlinePowerHousePrice,
			retailorPrice
		})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getProductsToSync = async (page, pageSize) => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getProductsToSync/?page=${page}&pageSize=${pageSize}`, {
			timeout: 0 // Set timeout to 0 for no timeout
		})
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const deleteImageLink = async (image) => {
	console.log(' in apiiiiiimage :>> ', image)
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.delete(`${process.env.REACT_APP_API_URL}user/deleteImage/`, {data: {imageUrl: image}})
		.then((res) => {
			console.log('response', response)
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getAllSPCodes = async () => {
	let response = []
	console.log('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL)
	await axios
		.get(`${process.env.REACT_APP_API_URL}admincrm/getAllSPCodes/`)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
