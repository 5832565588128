import React, {useEffect, useState} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import SearchSection from '../components/common/SearchSection'
import {Flexed, Spacer, Text} from '../styles/shared'
import {palette} from '../styles/colors'
import Button from '../components/common/Button'
import {getAllBanners, deleteBanner, addBanner} from '../api/apis'
import {useDispatch} from 'react-redux'
import AddBannerModal from '../components/modals/AddBannerModal'
import EditBannerModal from '../components/modals/EditBannerModal'
import CustomPagination from '../components/CustomPagination'
import {setIsLoading} from '../actions/authActions'
import {toastError, toastSuccess} from '../styles/toasterStyle'
import DeleteModal from '../components/modals/DeleteModal'

const Banners = () => {
	const [openAddBannerModal, setOpenAddBannerModal] = useState(false)
	const [openEditBannerModal, setOpenEditBannerModal] = useState(false)
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const [deleteId, setDeleteId] = useState(null)
	const [editData, setEditData] = useState('')
	const [pageNumber, setPageNumber] = useState(1)
	const [pagesAmount, setPagesAmount] = useState(1)
	const [totalNumberOfBanners, setTotalNumberOfBanners] = useState(1)
	const [data, setData] = useState([])
	const _dispatch = useDispatch()

	const doDeleteBanner = async () => {
		_dispatch(setIsLoading(true))
		console.log('deleteCustomerId', deleteId)
		const response = await deleteBanner(deleteId)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			doGetBanners()
			setDeleteId(null)
			setIsDeleteModalOpen(false)

			toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const doGetBanners = async () => {
		_dispatch(setIsLoading(true))

		const response = await getAllBanners(pageNumber, 6)

		console.log('responseresponse aa gya ', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			setPagesAmount(Math.ceil(response?.count / 6))
			setTotalNumberOfBanners(response?.count)
			setData(response?.data)
			console.log('data :>> ', data)
			// toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	useEffect(() => {
		doGetBanners()
	}, [pageNumber])

	return (
		<Wrapper>
			<Container fluid>
				<SearchSection title="Banners" />
				<Spacer height={1} />
				<Button
					ifClicked={() => {
						setOpenAddBannerModal(true)
					}}
					small
					width="11rem"
					icon="case.svg"
					label=" Add Banner"
				/>
				<Spacer height={1} />
				{/* <FlexedBanner flexWrap="wrap" justify="space-around"> */}
				<Background>
					<Row justifyContent="between">
						<Col lg={3}>
							<Text lineHeight={1.5} color="black" fontWeight={900} fontSize={1.5}>
								Banner Listing
							</Text>
						</Col>
						<Col lg={4}>
							<Stats justify="space-between" align="center" flexWrap="wrap">
								<Flexed gap={0.5} align="center" justify="center">
									<Circle color="active" />
									<Text color="active" lineHeight={1.5} fontWeight={700} fontSize={1.5}>
										Active {data?.filter((entry) => entry?.status === true).length}
									</Text>
								</Flexed>
								<Flexed gap={0.5} align="center" justify="center">
									<Circle color="inActive" />
									<Text color="inActive" lineHeight={1.5} fontWeight={700} fontSize={1.5}>
										In-Active {data?.filter((entry) => entry?.status === false).length}
									</Text>
								</Flexed>
							</Stats>
						</Col>
					</Row>
					<Row>
						{data?.map((banner, index) => (
							<>
								<Col md={12} lg={4}>
									<Spacer height={2} />
									<Banner key={index} banners={banner}>
										<BannerImage src={banner.images} />
										<FlexedIcons direction="row" align="center" justify="space-between">
											<StatusDiv status={banner?.status ? 'active' : 'inactive'}>
												<Text color="white" fontWeight={500} fontSize={1}>
													{banner?.status ? 'Active' : 'Inactive'}
												</Text>
											</StatusDiv>
											<Flexed gap={0.75}>
												<IconWrapper
													onClick={() => {
														setEditData(banner)
														setOpenEditBannerModal(true)
													}}>
													<Icon src="icons/pencil.svg" />
												</IconWrapper>
												<IconWrapper
													onClick={() => {
														setDeleteId(banner?.id)
														setIsDeleteModalOpen(true)
													}}>
													<Icon src="icons/delete.svg" />
												</IconWrapper>
											</Flexed>
										</FlexedIcons>
									</Banner>
								</Col>
							</>
						))}
					</Row>
					<Spacer height={1} />
					<CustomPagination pageNumber={pageNumber} pagesAmount={pagesAmount} tableData={data} totalData={totalNumberOfBanners} setPageNumber={setPageNumber} />
				</Background>
				{/* </FlexedBanner> */}
			</Container>
			{openAddBannerModal && (
				<AddBannerModal
					setOpenAddBannerModal={setOpenAddBannerModal}
					doGetBanners={doGetBanners}
					onClose={() => {
						setOpenAddBannerModal(false)
					}}
				/>
			)}
			{openEditBannerModal && (
				<EditBannerModal
					onClose={() => {
						setOpenEditBannerModal(false)
					}}
					doGetBanners={doGetBanners}
					editData={editData}
				/>
			)}
			{isDeleteModalOpen && (
				<DeleteModal
					onSubmit={() => {
						doDeleteBanner()
					}}
					label="Are you sure you want to delete this banner?"
					onClose={() => {
						setIsDeleteModalOpen(false)
					}}
				/>
			)}
		</Wrapper>
	)
}

const Stats = styled(Flexed)`
	width: 100%;
`
const CustomButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	color: ${palette.yellow};
	font-weight: 500;
	width: 16.5rem;
	height: 68px;
	border-radius: 0.625rem;
	// background-color: ${({name}) => (name === 'Pending Order' ? 'white' : name === 'Active Order' ? 'white' : 'white')};
`
const Circle = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: ${({color}) => palette[color]};
`
const Background = styled.div`
	background: white;
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 20px;
`

const StatusDiv = styled.div`
	background: ${({status}) => (status === 'active' ? palette.active : palette.danger)};
	padding: 0.5rem;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2rem;
	width: 4rem;
`

const FlexedIcons = styled(Flexed)`
	//   padding:0 1rem 0 1rem;
	width: 95%;
	position: absolute;
	top: 1rem;
`

const IconWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
`
const Icon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
`
const BannerImage = styled.img`
	height: 20rem;
	border-radius: 10px;
	width: 100%;
`
const Banner = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`
const FlexedBanner = styled(Flexed)`
	// padding:1rem 2.5rem;

	border-radius: 20px;
	width: 100%;
	background-color: white;
	//   position: relative;
`
const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	min-height: 100vh;
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`
export default Banners
