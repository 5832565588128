import React, {useState} from 'react'
import {styled} from 'styled-components'
import SideBar from './SideBar'
import {palette} from '../styles/colors'
import {media} from 'styled-bootstrap-grid'
import {useLocation} from 'react-router-dom'

const PageLayout = ({children}) => {
	const {pathname} = useLocation()
	const [menuToggle, setMenuToggle] = useState(false)
	const isUserLogIn = sessionStorage.getItem('authorization')

	return (
		<>
			<PageContainer>
				{isUserLogIn && (
					<>
						{pathname !== '/' && pathname !== '/forgotpassword' && pathname !== '/newpassword' && pathname !== '/backtologin' && pathname !== '/enterotp' && (
							<Sidebar menuToggle={menuToggle}>
								<SideBar menuToggle={menuToggle} setMenuToggle={setMenuToggle} />
							</Sidebar>
						)}
					</>
				)}
				<Content active={pathname === '/' || pathname === '/forgotpassword' || pathname === '/newpassword' || pathname === '/backtologin' || pathname === '/enterotp'}>{children}</Content>
				{/* <Content>{children}</Content> */}
			</PageContainer>
		</>
	)
}

const PageContainer = styled.div`
	display: flex;
`

const Sidebar = styled.div`
	width: 20rem;
	min-height: 100vh;
	height:100%;
	position: fixed;
	z-index: 2;
	transition: all 300ms linear;

	background-color: ${palette.black};
	${media.xs`
	width:${({menuToggle}) => (menuToggle ? '16rem' : '4rem')}
`}
	${media.sm`
	width:${({menuToggle}) => (menuToggle ? '16rem' : '4rem')}
`}
	${media.md`
	width:20rem;
`}
`

const Content = styled.div`
	/* padding-left: ${({active}) => (active ? '' : '20rem')}; */
	width: 100%;
	height: 100vh;
	${media.xs`
	padding-left: ${({active}) => (active ? '' : '4rem')};
	`}
	${media.sm`
	padding-left: ${({active}) => (active ? '' : '4rem')};
	`}
	${media.md`
	padding-left: ${({active}) => (active ? '' : '20rem')};
`}
`

export default PageLayout
