import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { palette } from "../styles/colors";
import { Container, Row, Col, media } from "styled-bootstrap-grid";
import { Flexed, Spacer, Text } from "../styles/shared";
import InputField from "../components/common/InputField";
import Button from "../components/common/Button";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../actions/authActions";
import { resetPassword } from "../api/apis";
import { useNavigate } from "react-router-dom";
import { toastError, toastSuccess } from "../styles/toasterStyle";
import ForgotPassword from "./ForgotPassword";
const NewPassword = () => {
  // const router = useRouter()
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
 
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
 

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const email = useSelector((state) => state.auth.email)
  console.log('email dekha', email )
  const _navigate = useNavigate()

	const _dispatch = useDispatch()
  useEffect(() => {
    if (email===null) {
      
      _navigate("/forgotpassword");
    }
  }, []);
  const handlePassword = async () => {
    _dispatch(setIsLoading(true))
    // const otpString = loginOtp.join("");
    const response = await resetPassword(newPassword,email)
    console.log('response', response)
    if (response?.success) {  
      _navigate('/backtologin')
      toastSuccess(response?.message);
    } else {
      toastError(response?.message);
    }
    _dispatch(setIsLoading(false))
  }

  const checkValidationOnClick = () => {
    let isValid = true;
    
    if (newPassword === "") {
      setNewPasswordError("new Password is required");
      isValid = false;
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("confirm password is required");
      isValid = false;
    }
    if (confirmPassword != newPassword) {
      setConfirmPasswordError("password does not match");
      
      isValid = false;
    }
    return isValid;
  };
  return (
    <>
     <CustomContainer fluid>
        <RowWrapper justifyContent="center" alignItems="center">
          <Wrapper>
            <ColWrapper lg={8}>
              <Flexed direction="column" justify="space-evenly" width={`70%`}>
                <Flexed justify="center">
                  <Logo src="/icons/lockimg.png" alt="logo" />
               
                </Flexed>

                <Flexed direction="column">
                  <Text fontSize={2.5} fontWeight="800" color="black" lineHeight={3}>
                  Create a new password
                  </Text>

                  <Spacer height={2} />

                  <InputField
                    labelType="large"
                    value={newPassword}
                    label="New Password"
                    type="password"
                    // placeholder="Password"
                    handleChange={(e) => {
                      setNewPasswordError("");
                      if (e === "") {
                        setNewPasswordError("new password is required");
                      }
                      setNewPassword(e);
                    }}
                    error={newPasswordError}
                    errorMsg={newPasswordError}
                    required
                  />

                  <Spacer height={1} />

                  <InputField
                    labelType="large"
                    value={confirmPassword}
                    label="Confirm Password"
                    type="password"
                    // placeholder="Password"
                    handleChange={(e) => {
                      setConfirmPasswordError("");
                      if (e === "") {
                        setConfirmPasswordError("confirm password is required");
                      }
                      setConfirmPassword(e);
                    }}
                    error={confirmPasswordError}
                    errorMsg={confirmPasswordError}
                    required
                  />

                  <Spacer height={1} />

                  {/* <Flexed justify="end">
                    <Text color="yellow" fontWeight="400" fontSize="0.875">
                      Forgot Password?
                    </Text>
                  </Flexed> */}

                  <Spacer height={1} />
                  <Button
                    width="100%"
                    ifClicked={() => {
                      if (checkValidationOnClick()) 
                      handlePassword()
                      
                    }}
                    label="Create a new password"
                  />
                </Flexed>
              </Flexed>
            </ColWrapper>
            {/* <ImageWrapper> */}
            <CustomhideCol lg={4}>
              <Image src="/icons/getstarted.png" />
            </CustomhideCol>
            {/* </ImageWrapper> */}
          </Wrapper>
        </RowWrapper>
      </CustomContainer>
	
    </>
  );
};

const CustomCol = styled(Col)`
  display: flex;

  gap: 20px;
`;
const CustomhideCol = styled(Col)`
  ${media.xs`
display:none;
`}
  ${media.sm`
display:none;
`}
${media.md`
display:none;
`}
${media.lg`
padding:0px;
display:block;
height: 85vh;
width: 100%;
border-radius: 0rem 2rem 2rem 0rem;
`}
`;
const ImageWrapper = styled.div`
  ${media.xs`
display:none;
`}
  ${media.sm`
display:none;
`}
${media.md`
display:none;
`}
${media.lg`

display:block;
height: 85vh;
width: 100%;
border-radius: 0rem 2rem 2rem 0rem;
`}
`;

const Image = styled.img`
  height: 85vh;
  width: 100%;
  border-radius: 0rem 2rem 2rem 0rem;
`;
const Logo = styled.img`
  width:32%;

`;

const CustomContainer = styled(Container)`
  background-color: ${palette.black};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
`;

const RowWrapper = styled(Row)`
  width: 100%;

  margin-left: 0px !important;
`;

const ColWrapper = styled(Col)`
  display: flex;

  justify-content: center; /* Center horizontally */
`;

const Wrapper = styled.div`
  width: 85%;
  height: 85vh;
  background-color: ${palette.white};
  border-radius: 2.106rem;
  display: flex;
`;
export default NewPassword;
