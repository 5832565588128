import React, {useEffect, useState} from 'react'
import {Container, media} from 'styled-bootstrap-grid'
import {styled} from 'styled-components'
import {palette} from '../styles/colors'
import SearchSection from '../components/common/SearchSection'
import {Flexed, Spacer, Text} from '../styles/shared'
import DropDown from '../components/common/DropDown'
import FeedBackTable from '../components/tables/FeedBackTable'
import CustomDropDown from '../components/common/CustomDropDown'
import {toastError, toastSuccess} from '../styles/toasterStyle'
import {setIsLoading} from '../actions/authActions'
import {deleteFeedBack, getFeedBacks} from '../api/apis'
import {useDispatch} from 'react-redux'
import DateSelector from '../components/common/DateSelector'
import DeleteModal from '../components/modals/DeleteModal'

const Feedbacks = () => {
	const [pageNumber, setPageNumber] = useState(1)
	const [pagesAmount, setPagesAmount] = useState(1)
	const [totalNumberOfFeedbacks, setTotalNumberOfFeedbacks] = useState(1)
	const [filterOptions, setFilterOptions] = useState([])
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const [deleteFeedBackId, setDeleteFeedBackId] = useState(null)
	// const [deleteFeedBackId, setDeleteFeedBackId] = useState(null)
	// getFeedBacks
	// getFeedBacks
	useEffect(() => {
		doGetFeedBacks()
	}, [pageNumber])
	const [feedbacksTableData, setFeedbacksTableData] = useState([])
	const _dispatch = useDispatch()

	const doGetFeedBacks = async () => {
		_dispatch(setIsLoading(true))

		const response = await getFeedBacks(pageNumber, 6)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			setPagesAmount(Math.ceil(response?.count / 6))
			setTotalNumberOfFeedbacks(response?.count)
			const filterData = []
			const uniqueItems = response?.data?.filter((item, index, self) => index === self.findIndex((t) => t.value === item.value))
			console.log('uniqueItems', uniqueItems)
			uniqueItems.map((item) =>
				filterData.push({
					label: item.value,
					value: item.value
				})
			)
			setFilterOptions(filterData)
			console.log('////////////////////////', filterOptions)
			const apiRows = response?.data?.map((feedBack,index) => ({
				no: (
					<Text type="medium" color="black" fontWeight={300}>
						{index+1}
					</Text>
				),
				userName: (
					<Text type="medium" color="black" fontWeight={300}>
						{feedBack?.user?.customer_name}
					</Text>
				),
				review: (
					<Text type="medium" color="black" fontWeight={300}>
						{feedBack?.value}
					</Text>
				),
				feedback: (
					<Text type="medium" color="black" fontWeight={300}>
						{feedBack?.description}
					</Text>
				),
				dated: (
					<Text type="medium" color="black" fontWeight={300}>
						{feedBack?.createdAt}
					</Text>
				),

				icon: (
					<Flexed direction="row" align="center" gap={0.75} justify="flex-start">
						{/* <IconWrapper>
							<Icon src="/icons/pencil.svg" />
						</IconWrapper> */}
						<IconWrapper
							onClick={() => {
								setDeleteFeedBackId(feedBack?.id)
								setIsDeleteModalOpen(true)
							}}>
							<Icon src="/icons/delete.svg" />
						</IconWrapper>
					</Flexed>
				)
			}))
			setFeedbacksTableData(apiRows)
			// toastSuccess(response?.message)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}
	///Delete Feedback
	const doDeleteFeedBack = async () => {
		_dispatch(setIsLoading(true))
		console.log('deleteFeedBackId', deleteFeedBackId)
		const response = await deleteFeedBack(deleteFeedBackId)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			toastSuccess(response?.message)
			await doGetFeedBacks()
			setDeleteFeedBackId(null)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}
	return (
		<Wrapper>
			<Container fluid>
				<SearchSection title="Feedbacks" />
				<Spacer height={0.625} />
				<Flexed direction="row" align="center" justify="flex-end">
					<Flexed direction="row" align="center" flexWrap="wrap" gap={1}>
						<Text type="xlarge" color="black" fontWeight={700}>
							Filter By:
						</Text>
						<Flexed direction="row " flexWrap="wrap" align="center" gap={0.5}>
							<CustomDropDown
								label="Review"
								styledHeight={6}
								// firstSelected={'Review'}
								hasChanged={(value) => {
									// setSelectedDueDateError('')
									// if (value == '') {
									// 	setSelectedDueDateError('due is Required')
									// }
									// setSelectedDueDate(value)
								}}
								width="8"
								//feed
								// required
								// error={selectedDueDateError}
								// errorMsg={selectedDueDateError}
								options={filterOptions}
							/>
							<DateSelector />
							{/* <CustomDropDown
								label="Date"
								styledHeight={6}
								// firstSelected={selectedEditProduct?.company?.company_name}
								hasChanged={(value) => {
									// setSelectedDueDateError('')
									// if (value == '') {
									// 	setSelectedDueDateError('due is Required')
									// }
									// setSelectedDueDate(value)
								}}
								width="8"
								// required
								// error={selectedDueDateError}
								// errorMsg={selectedDueDateError}
								options={filterOptions}
							/> */}
						</Flexed>
					</Flexed>
				</Flexed>
				<Spacer height={0.625} />
				<TableWrapper>
					<FeedBackTable feedbacksTableData={feedbacksTableData} pageNumber={pageNumber} setPageNumber={setPageNumber} pagesAmount={pagesAmount} totalNumberOfFeedbacks={totalNumberOfFeedbacks} />
				</TableWrapper>
			</Container>
			{isDeleteModalOpen && (
				<DeleteModal
					onSubmit={() => {
						setIsDeleteModalOpen(false)
						doDeleteFeedBack()
					}}
					label="Are you sure you want to delete this feedBack?"
					onClose={() => {
						setIsDeleteModalOpen(false)
					}}
				/>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background-color: ${palette.lightGrey};
	height: 100vh;
	${media.xs`
	padding: 1rem 1rem;
	`}
	${media.sm`
	padding: 1rem 1rem;
	`}
	${media.md`
	padding: 1.625rem 1.25rem 2.625rem 1.25rem;
	`}
`

const TableWrapper = styled.div`
	border-radius: 0.625rem;
	background-color: ${palette.white};
	padding: 0.75rem;
`

const IconWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Icon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
`

export default Feedbacks
