import * as types from '../actions/types'

const initialState = {
	isDarkTheme: false,
	email: null,
	isLoading: false,
	userName: ''
}

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case types.SWITCH_THEME: {
			return {
				...state,
				isDarkTheme: action.value
			}
		}

		case types.IS_LOADING:
			return {
				...state,
				isLoading: action.value
			}
		case types.EMAIL:
			return {
				...state,
				email: action.value
			}
		case types.USERNAME:
			return {
				...state,
				userName: action.value
			}

		default:
			return state
	}
}
