import React, {useState} from 'react'
import {Col, Row, media} from 'styled-bootstrap-grid'
import {Flexed, Spacer, Text} from '../../styles/shared'
import {ImExit} from 'react-icons/im'
import {styled} from 'styled-components'
import {palette} from '../../styles/colors'
// import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import InputField from './InputField'
import InputSearch from './InputSearch'
import {useSelector} from 'react-redux'

const SearchSection = ({title, noSearch}) => {
	const [search, setSearch] = useState('')
	// const _dispatch = useDispatch()
	// const userName = useSelector((state) => state.auth.userName)
	const userName = sessionStorage.getItem('userName')
	const halfName = userName?.split(' ')
	const _navigate = useNavigate()

	return (
		<Row justifyContent="between" alignItems="center">
			<Col xl={9}>
				<Spacer height={1} />

				<BgWrapper>
					<CustomRow justifyContent="between" alignItems="center">
						{/* <Spacer height={1} /> */}
						<Col xl={3} md={4} sm={6}>
							<Text fontSize={2} color="white" fontWeight={400} lineHeight={2.1}>
								{title}
							</Text>
						</Col>

						{!noSearch && (
							<Col xl={6} md={12}>
								{/* <Spacer height={0.5} /> */}
								<InputSearch
									handleChange={(e) => {
										setSearch(e)
									}}
									placeholder="Search"
								/>
							</Col>
						)}
						<Col xl={2} lg={12} md={12}>
							{/* <Spacer height={0.5} /> */}
							<CustomFlex direction="row" justify="flex-end">
								<div>
									<NotificationWrapper>
										<Icon src="icons/bell.svg" />
									</NotificationWrapper>
								</div>
							</CustomFlex>
						</Col>
					</CustomRow>
				</BgWrapper>
			</Col>
			<CustomCol xl={3}>
				<Spacer height={1} />
				<ProfileWrapper>
					<Flexed direction="row" gap={0.75}>
						<div>
							<ProfilePic>
								<Icon src="icons/profile.png" />
							</ProfilePic>
						</div>
						<div>
							<Text fontWeight={600} fontSize={1.5} lineHeight={1.813} color="pureBlack">
								{userName && halfName[0]}
							</Text>
							<Text typee="xsmall" color="pureBlack">
								Super Admin
							</Text>
						</div>
					</Flexed>
					<div>
						<ExitWrapper
							onClick={() => {
								sessionStorage.removeItem('authorization')

								_navigate('/')
							}}>
							<Icon src="icons/exit.svg" />
						</ExitWrapper>
					</div>
				</ProfileWrapper>
			</CustomCol>
		</Row>
	)
}

const CustomFlex = styled(Flexed)`
	width: 100%;
	cursor: pointer;
`
const CustomCol = styled(Col)`
	/* padding: 0; */
`
const BgWrapper = styled.div`
	background-color: ${palette.black};
	border-radius: 0.625rem;
`

const ProfileWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 72.02px;
	background-color: ${palette.white};
	border-radius: 0.625rem;
	padding: 0.75rem;
`

const ExitWrapper = styled.div`
	height: 2.875rem;
	width: 2.875rem;
	background-color: ${palette.black};
	padding: 0.563rem;
	border-radius: 0.625rem;
	cursor: pointer;
`
const NotificationWrapper = styled.div`
	background-color: ${palette.yellow};
	border-radius: 0.625rem;
	padding: 0.675rem;
	width: 3.375rem;
	height: 3.375rem;
`

const Icon = styled.img`
	width: 100%;
	height: 100%;
`

const ProfilePic = styled.div`
	height: 3rem;
	width: 3rem;
`

const CustomRow = styled(Row)`
	padding: 0.563rem 15px 0.563rem 15px;
	/* background-color: ${palette.black}; */
	@media (max-width: 1200px) {
		gap: 1rem;
	}
`
const StyledFlex = styled(Flexed)`
	cursor: pointer;

	&:hover {
		color: ${palette.yellow};
	}
	&:hover ${Text} {
		color: ${palette.yellow};
	}
`

export default SearchSection
