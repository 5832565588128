import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import SuccessModal from './SuccessModal'
import {Flexed, Heading, Spacer, Text} from '../../styles/shared'
import CustomDropDown from '../common/CustomDropDown'
import Button from '../common/Button'
import CustomPagination from '../CustomPagination'
import WaitgettingProductModal from './WaitgettingProductModal'
import {palette} from '../../styles/colors'
import CustomCheckBox from '../common/CustomCheckBox'
import {setIsLoading} from '../../actions/authActions'
import {toastError, toastSuccess} from '../../styles/toasterStyle'
import {useDispatch} from 'react-redux'
import {getProductsToSync, syncProducts} from '../../api/apis'
import MultipleSelectCheckbox from '../common/MultipleSelectCheckbox'

const SyncModal = ({onClose, productsData, productsGroup, doGetProducts}) => {
	const _dispatch = useDispatch()
	const [data, setData] = useState([])
	const [pageNumber, setPageNumber] = useState(1)
	const [pagesAmount, setPagesAmount] = useState(1)
	const [totalNumberOfSyncProducts, setTotalNumberOfSyncProducts] = useState(1)
	const [openSuccessModal, setOpenSuccessModal] = useState(false)
	const [waitgettingProductModal, setWaitgettingProductModal] = useState(false)
	const [filterOptions, setFilterOptions] = useState([])
	const [selectedProductsGroup, setSelectedProductsGroup] = useState([])

	const doSyncProducts = async () => {
		_dispatch(setIsLoading(true))

		const dataOptions = {
			productName: selectedRows.includes('productName'),
			productDesc: selectedRows.includes('productDesc'),
			productQuantity: selectedRows.includes('productQuantity'),
			defaultSellPrice: selectedRows.includes('defaultSellPrice'),
			distributerPrice: selectedRows.includes('distributerPrice'),
			onlinePowerHousePrice: selectedRows.includes('onlinePowerHousePrice'),
			retailorPrice: selectedRows.includes('retailorPrice')
		}

		const response = await syncProducts(selectedProductsGroup, dataOptions)

		console.log('responseresponse', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			toastSuccess(response?.message)
			setOpenSuccessModal(true)
			doGetProducts()
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	useEffect(() => {
		console.log('hi i am data in modal :>> ', productsData)
		console.log('selectedRows :>> ', selectedRows)
		setTotalNumberOfSyncProducts(productsData?.count)
		setPagesAmount(Math.ceil(productsData?.count / 6))
		setTotalNumberOfSyncProducts(productsData?.count)
		setData(
			productsData?.data.map((item, index) => {
				return {
					id: index + 1,
					productName: item.productName,
					productDesc: item.productDesc,
					productQuantity: item.productQuantity === 'None' ? item.productQuantity : parseFloat(item.productQuantity),
					defaultSellPrice: item.defaultSellPrice === 'None' ? item.defaultSellPrice : parseFloat(item.defaultSellPrice),
					distributerPrice: item.distributerPrice === 'None' ? item.distributerPrice : parseFloat(item.distributerPrice),
					onlinePowerHousePrice: item.onlinePowerHousePrice === 'None' ? item.onlinePowerHousePrice : parseFloat(item.onlinePowerHousePrice),
					retailorPrice: item.retailorPrice === 'None' ? item.retailorPrice : parseFloat(item.retailorPrice)
				}
			})
		)
		// console.log('data :>> ', data);
		setFilterOptions(
			productsGroup.map((item, index) => {
				return {
					label: item?.groupName,
					value: item?.groupName,
					checked: selectedProductsGroup.includes(item?.groupName)
				}
			})
		)
		const selectall = []
		productsGroup?.map((item) => selectall.push(item?.groupName))
		setSelectedProductsGroup(selectall)
		console.log('selectedProductsGroup :>> ', selectedProductsGroup)
		console.log('neeww filtered options :>> ', filterOptions)
	}, [])

	const doCallProducts = async () => {
		_dispatch(setIsLoading(true))
		const response = await getProductsToSync(pageNumber, 6)

		console.log('responseresponse aa gya ', response)
		// console.log('responseresponse', response)
		if (response?.success) {
			setPagesAmount(Math.ceil(response?.count / 6))
			setTotalNumberOfSyncProducts(response?.count)
			setData(
				response?.data.map((item, index) => {
					return {
						id: index + 1,
						productName: item.productName,
						productDesc: item.productDesc,
						productQuantity: item.productQuantity === 'None' ? item.productQuantity : parseFloat(item.productQuantity),
						defaultSellPrice: item.defaultSellPrice === 'None' ? item.defaultSellPrice : parseFloat(item.defaultSellPrice),
						distributerPrice: item.distributerPrice === 'None' ? item.distributerPrice : parseFloat(item.distributerPrice),
						onlinePowerHousePrice: item.onlinePowerHousePrice === 'None' ? item.onlinePowerHousePrice : parseFloat(item.onlinePowerHousePrice),
						retailorPrice: item.retailorPrice === 'None' ? item.retailorPrice : parseFloat(item.retailorPrice)
					}
				})
			)
		} else {
			toastError(response?.message)
		}
		_dispatch(setIsLoading(false))
	}

	const columns = [
		{id: 'id', label: 'PID'},
		{id: 'productName', label: 'Name'},
		{id: 'productDesc', label: 'Description'},
		{id: 'productQuantity', label: 'Qty'},
		{id: 'defaultSellPrice', label: 'Default'},
		{id: 'distributerPrice', label: 'Distributor'},
		{id: 'onlinePowerHousePrice', label: 'Online'},
		{id: 'retailorPrice', label: 'Retail'}
	]

	const [selectedRows, setSelectedRows] = useState(['productName', 'productDesc', 'productQuantity', 'defaultSellPrice', 'distributerPrice', 'onlinePowerHousePrice', 'retailorPrice'])

	const handleCheckboxChange = (id, isChecked) => {
		if (isChecked) {
			// Add ID to selectedRows if it's not already present
			if (!selectedRows.includes(id)) {
				setSelectedRows([...selectedRows, id])
			}
		} else {
			// Remove ID from selectedRows
			setSelectedRows(selectedRows.filter((rowId) => rowId !== id))
		}
	}

	const handleProductGroupChange = (id) => {
		if (!selectedProductsGroup.includes(id)) {
			setSelectedProductsGroup([...selectedProductsGroup, id])
		} else {
			// Remove ID from selectedRows
			setSelectedProductsGroup(selectedProductsGroup.filter((rowId) => rowId !== id))
		}
	}
	useEffect(() => {
		doCallProducts()
	}, [pageNumber])

	useEffect(() => {
		setFilterOptions(
			productsGroup.map((item, index) => {
				return {
					label: item?.groupName,
					value: item?.groupName,
					checked: selectedProductsGroup.includes(item?.groupName)
				}
			})
		)
	}, [selectedProductsGroup])

	return (
		<>
			<Modal
				open={true}
				onClose={onClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
				showCloseIcon={false}>
				<Spacer height={0.65} />
				<Heading isCentered fontWeight="700" fontSize="1.5" color="black" lineHeight="1.5">
					Sync Products from Unleashed
				</Heading>
				<Spacer height={2} />
				<CustomFlexed justify="flex-end">
					{/* <CustomDropDown
            label="Product Groups"
            styledHeight={6}

            // firstSelected={selectedEditProduct?.company?.company_name}
            hasChanged={(value) => {
              // setSelectedDueDateError('')
              // if (value == '') {
              // 	setSelectedDueDateError('due is Required')
              // }
              // setCheckedValue(value)
              handleProductGroupChange(value)
            }}
            width="20"
            // required
            // error={selectedDueDateError}
            // errorMsg={selectedDueDateError}
            options={filterOptions}
          /> */}
					<MultipleSelectCheckbox
						label="Product Groups"
						styledHeight={6}
						width="13"
						options={filterOptions}
						hasChanged={(value) => {
							handleProductGroupChange(value)
						}}
					/>
				</CustomFlexed>
				<Flexed>
					<Checkbox
						type="checkbox"
						onChange={(e) => {
							selectedRows.length === 7 ? setSelectedRows([]) : setSelectedRows(['productName', 'productDesc', 'productQuantity', 'defaultSellPrice', 'distributerPrice', 'onlinePowerHousePrice', 'retailorPrice'])
						}}
						checked={selectedRows.length === 7}
					/>
					<Text type="medium" color="black" fontWeight={300}>
						Select All
					</Text>
				</Flexed>
				<Spacer height={1} />
				<Scroll>
					<StyledTable>
						<thead>
							<tr>
								{columns.map((column, index) => (
									<TableHeading key={column.id} index={index} totalColumns={columns.length}>
										{column?.id !== 'id' && (
											<>
												<Checkbox type="checkbox" name={column.id} checked={selectedRows.some((row) => row === column.id) ? true : false} onChange={(e) => handleCheckboxChange(column.id, e.target.checked)} />
											</>
										)}
										{column.label}
									</TableHeading>
								))}
							</tr>
						</thead>
						<tbody>
							{data.map((row, index) => (
								<TableRow key={index}>
									{columns.map((column) => (
										<TableData key={column.id}>{row[column.id]}</TableData>
									))}
								</TableRow>
							))}
						</tbody>
					</StyledTable>
				</Scroll>
				<Spacer height={1} />
				<CustomPagination tableData={data} totalData={totalNumberOfSyncProducts} pagesAmount={pagesAmount} pageNumber={pageNumber} setPageNumber={setPageNumber} />
				<Spacer height={2} />
				<CustomFlexed justify="space-around" gap="1" flexWrap="wrap">
					<Button
						width="13rem"
						hasBorder
						ifClicked={() => {
							onClose(false)
						}}
						label="Cancel"
					/>

					<Button
						width="13rem"
						ifClicked={() => {
							doSyncProducts()
						}}
						label="Confirm"
					/>
				</CustomFlexed>
			</Modal>
			{openSuccessModal && (
				<SuccessModal
					label="Products synced successfully"
					onClose={async () => {
						setOpenSuccessModal(false)
						onClose()
					}}
				/>
			)}
			{waitgettingProductModal && (
				<WaitgettingProductModal
					// label="Banner Added successfully"
					onClose={() => {
						setWaitgettingProductModal(false)
					}}
				/>
			)}
		</>
	)
}
const Scroll = styled.div`
	overflow-x: scroll;

	&::-webkit-scrollbar {
		width: 0.3rem !important;
		height: 0.1rem !important;
	}

	&::-webkit-scrollbar-track {
		background: #eee;
		border-radius: 1rem;
		border: 0.01rem solid ${palette.silver};
		border-width: 0rem 0.1rem 0rem 0rem;
	}

	&::-webkit-scrollbar-thumb {
		background: ${palette.yellow};
		border-radius: 1rem;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`
const TableRow = styled.tr`
	height: 1rem !important;
`

const CustomFlexed = styled(Flexed)`
	width: 100%;
`

const StyledTable = styled.table`
	width: 100%;
	height: 27rem;
	border: 1px transparent;
	border-radius: 10px !important;
	box-shadow: 3px 20px 31px 9px #ddd;
`
const TableHeading = styled.th`
	padding: 1.375rem 8px;
	border-radius: ${({index, totalColumns}) =>
		// If it's the first column, apply rounded corners on the left side
		index === 0
			? '10px 0 0 0'
			: // If it's the last column, apply rounded corners on the right side
			index === totalColumns - 1
			? '0 10px 0 0'
			: // Otherwise, no rounded corners
			  '0'};

	//   height: 100%;
	// text-align: center;
	background: #e1e1e1;
	font-size: 20px;
	font-weight: 500;
	line-height: 22px;
`
const TableData = styled.td`
	padding: 1.375rem 8px;
	//   height: 1rem;
	// text-align: center;
	border-bottom: 1px solid #ddd;
`

const Checkbox = styled.input`
	accent-color: orange;
`

export default SyncModal
